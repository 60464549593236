<template>
    <div class="table-container">
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="b-table-sticky-header table-responsive-true">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead>
                <tr>
                    <th rowspan="2" class="toggle-show" style="width: 20px">
                        <button type="button" class="btn btn-secondary"
                                @click="collapseAll = !collapseAll;">
                            <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                            <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                        </button>
                    </th>
                    <th rowspan="2">наименование</th>
                    <th colspan="3" class="text-center">Плановый период</th>
                </tr>
                <tr>
                    <template v-for="(year, index) in curYear.periods">
                        <th :key="index" class="text-center" width="25%">{{ year }} {{ "ГОД" }}</th>
                    </template>
                </tr>
                </thead>
                <tbody>
                <template v-for="(prgItem, prgIndex) of programsArr">
                    <tr :key="'prgItemIndx'+prgIndex" class="table-info">
                        <td class="toggle-show">
                            <button type="button" class="btn btn-secondary"
                                    @click="collapseItems(prgIndex)">
                                <i class="icon icon-chevron-circle"
                                   v-if="collapseArr[prgIndex]"></i>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                            </button>
                        </td>
                        <td colspan="8">{{ prgItem.name_ru }}</td>
                    </tr>
                    <template v-if="!collapseArr[prgIndex]">
                        <template v-for="(goalItem, goalIndex) of goalsArr">
                            <template
                                v-if="goalItem!==null && parseInt(goalItem.forecastId)===parseInt(prgItem.forecastId) && parseInt(goalItem.programId)===parseInt(prgItem.id)">
                                <tr :key="`goalItemIndex${goalIndex}_${prgIndex}`"
                                    class="table-info"
                                    style="background-color: rgb(236, 247, 255);">
                                    <td></td>
                                    <td class="toggle-show" style="width: 20px;">
                                        <button type="button" class="btn btn-secondary"
                                                @click="collapseItemsSec(goalIndex)">
                                            <i class="icon icon-chevron-circle"
                                               v-if="collapseArrSec[goalIndex]"></i>
                                            <i class="icon icon-chevron-circle icon-rotate-180"
                                               v-else></i>
                                        </button>
                                    </td>
                                    <td colspan="7">{{ goalItem.name_ru }}</td>
                                </tr>

                                <template v-if="!collapseArrSec[goalIndex]">
                                    <template
                                        v-for="(indItem, indIndex) of indicatorsArr">
                                        <template
                                            v-if="indItem!==null && parseInt(indItem.forecastId)===parseInt(goalItem.forecastId) && parseInt(indItem.programId)===parseInt(goalItem.programId) && parseInt(indItem.goalId)===parseInt(goalItem.id)">

                                            <tr :key="`indicatorItemIndexes${prgIndex}_${goalIndex}_${indIndex}`">
                                                <td></td>
                                                <td :key="`indicatorItemIndex${prgIndex}_${goalIndex}_${indIndex}`">
                                                    {{ indItem.name_ru }}:
                                                    <template
                                                        v-if="indItem.planValue">
                                                        {{
                                                            indItem.planValue.toLocaleString()
                                                        }}
                                                    </template>
                                                    <template
                                                        v-if="indItem.planValue">
                                                        тыс. тенге
                                                    </template>
                                                </td>
                                                <td v-for="(year, index) in curYear.periods"
                                                    :key="index">
                                                    <div class="exp-textarea-style"
                                                         v-bind:class="[allowable(indItem[year]) ? descriptionNotEmpty : descriptionEmpty]"
                                                         style="text-align: justify">
                                                            <span class="enf-placeholder"
                                                                  v-if="!(indItem[year] && indItem[year].nameRu)">
                                                                {{
                                                                    (indItem[year] && indItem[year].isEditable) ? "Введите описание" : ""
                                                                }}
                                                            </span>
                                                        <template
                                                            v-if="indItem[year] && indItem[year].nameRu">
                                                            <template
                                                                v-if="indItem[year].nameRu.length < 150">
                                                                {{
                                                                    indItem[year].nameRu
                                                                }}
                                                            </template>
                                                            <template v-else>
                                                                {{
                                                                    indItem[year].nameRu.slice(0, 150)
                                                                }}...
                                                            </template>
                                                        </template>

                                                        <template
                                                            v-if="editAccess && (indItem[year] &&
                                                             indItem[year].isEditable)">
                                                            <i class="tas-action icon icon-pencil-edit"
                                                               @click="editDescription('ru', true, indItem, goalItem, prgItem, year)"></i>
                                                        </template>
                                                        <template v-else>
                                                            <i class="tas-action icon icon-eye-open"
                                                               @click="editDescription('ru',
                                                               (indItem[year] &&
                                                               indItem[year].isEditable), indItem, goalItem, prgItem, year)"></i>
                                                        </template>

                                                    </div>

                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>

                            </template>
                        </template>
                    </template>
                </template>
                </tbody>
            </table>
        </div>
        <b-modal
            v-model="modalVisible"
            modal-class="enf-modal"
            size="lg"
            centered
            :title="modalTitle"
            hide-footer
        >
            <b-form-group>
                <template #label>{{ 'На русском:' }}</template>
                <template v-if="editAccess">
                    <b-form-textarea placeholder="введите описание"
                                     v-model="descriptionAreaNew.nameRu"
                                     style="text-align: justify"></b-form-textarea>
                </template>
                <template v-else>
                    <b-form-textarea readonly v-model="descriptionAreaNew.nameRu"
                                     style="text-align: justify"></b-form-textarea>
                </template>

            </b-form-group>
            <b-form-group>
                <template #label>{{ 'Қазақ тілінде:' }}</template>
                <template v-if="editAccess">
                    <b-form-textarea placeholder="сипаттаманы енгізіңіз"
                                     v-model="descriptionAreaNew.nameKk"
                                     style="text-align: justify"></b-form-textarea>
                </template>
                <template v-else>
                    <b-form-textarea readonly v-model="descriptionAreaNew.nameKk"
                                     style="text-align: justify"></b-form-textarea>
                </template>
            </b-form-group>

            <template v-if="editAccess">
                <b-button variant="primary" @click="addDescriptionForm">Сохранить</b-button>
            </template>
            <b-button variant="light" @click="closeDescriptionForm">Отменить</b-button>
        </b-modal>
    </div>
</template>

<script>
import {makeToast} from "@/modules/budget/bip/bip-types";
import VueElementLoading from 'vue-element-loading';

export default {
    name: 'ExplanatoryNoteAddTable',
    components: {
        'loading': VueElementLoading
    },
    props: {
        curPrg: {
            type: Object,
            required: true
        },
        curYear: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curRegion: {
            type: Object,
            required: true
        },
        curAbp: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curGu: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curGkkp: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        isGkkp: {
            type: Boolean,
            required: false
        },
        curBudgetType: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curBudgetVariant: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        saveBtnPressed: {
            type: Boolean,
            required: false
        },
        editAccess: {
            type: Boolean,
            required: false
        },
        uniqueYears: {
            type: Set,
            required: false
        },
        needSave: {
            type: Boolean,
            required: false
        },

    },
    watch: {
        needSave: function (value) {
            if (value === true) {
                this.$emit('needSaved', false);
                const arrIndicator = onlyFilledFields(this.indicatorsArr);
                this.$emit('indicatorsArr', arrIndicator);
            }

            function onlyFilledFields(indicatorsArrNew) {
                const arr = [];
                if (indicatorsArrNew.length > 0) {
                    for (const indicator of indicatorsArrNew) {
                        arr.push(indicator);
                    }
                }
                return arr;
            }
        }
    },
    data() {
        return {
            descriptionAreaNew: {
                nameRu: '',
                nameKk: '',
                nameEn: ''
            },
            descYear: null,
            descriptionEmpty: 'text-area-block',
            descriptionNotEmpty: 'text-area-block-empty',
            ru: 'ru',
            kk: 'kk',
            targetItems: {
                indicator: null,
                descrId: null,
                lang: null,
                forecastId: null,
                prgId: null,
                goalId: null,
                indicatorId: null
            },
            loading: false,
            collapseAll: true,
            saveTarget: true,
            modalVisible: false,
            modalTitle: '',
            description_part: '',
            descriptionArea: '',
            programsArr: [],
            goalsArr: [],
            indicatorsArr: [],
            forecastYears: [],
            collapseArr: [],
            collapseArrSec: [],
            collapseArrThird: [],
            isFull: true,
        }
    },
    async created() {
        await this.getForecastData();
        this.$watch('collapseAll', this.collapseAllFunc);
    },
    async mounted() {


    },
    methods: {
        allowable(descItem) {
            if (this.saveBtnPressed === true) {
                this.descriptionEmpty = 'text-area-block-empty';
                this.descriptionNotEmpty = 'text-area-block';
                if ((descItem.nameRu.length > 0 || descItem.nameKk.length > 0) &&
                    this.editAccess && descItem.isEditable) {
                    return true;
                } else if (!descItem.isEditable) {
                    return true;
                }
                this.isFull = false;
            }
            return false;
        },

        async getForecastData() {
            try {
                const url = '/api/explanatory-note/budget-request-note/forecast/' +
                    this.curAbp.abp + '/' +
                    this.curPrg.prg + '/' +
                    this.curYear.year;

                const response = await fetch(url).then(response => response.json());
                if (response) {
                    this.extractSmallArrays(response);
                    this.collapseHide(this.collapseAll);
                    //await this.getTargetIndicatorData();
                    await this.getTargetIndicatorDataNew();
                    this.loading = false;
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки "Целевых индикаторов"', error.toString());
                this.loading = false;
            }
        },

        extractSmallArrays(bigObj) {
            this.programsArr = [];
            this.goalsArr = [];
            this.indicatorsArr = [];
            if (bigObj.programList.length > 0 && bigObj.goalList.length > 0 && bigObj.indicatorList.length > 0) {
                const prg = this.removeDuplicates(bigObj.programList);
                this.putToArr(prg, 'program');
                this.putToArr(bigObj.goalList, 'goal');
                this.putToArr(bigObj.indicatorList, 'indicator');
            }
            console.log("TAB-TWO-------------------------------------");
            console.log(this.indicatorsArr)
        },

        putToArr(tempArr, val) {
            if (tempArr.length > 0) {
                for (const item of tempArr) {
                    if (val === 'program') {
                        this.programsArr.push(item);
                    } else if (val === 'goal') {
                        this.goalsArr.push(item);
                    } else if (val === 'indicator') {
                        this.indicatorsArr.push(item);
                    }
                }
            }
        },

        removeDuplicates(arr) {
            return arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id);
                if (!x) {
                    return enteredArr.concat([current]);
                } else {
                    return enteredArr;
                }
            }, []);
        }, //--Удалить дубликаты

        async getTargetIndicatorData() {

            try {

                const url =
                    `/api/explanatory-note/get-ind?abp=${this.curAbp.abp}&prg=${this.curPrg.prg}&gu=${this.curGu.gu}&dataTypeId=${this.curBudgetType.budget}&year=${this.curYear.year}&region=${this.curRegion.region}&variant=${this.curBudgetVariant.variant}&gkkp=${this.curGkkp?.code}&is_gkkp=${this.isGkkp}`;

                const response = await fetch(url).then(response => response.json());
                if (response) {
                    if (response.length > 0) {
                        // this.setTargetIndicator(response);
                    }
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки "Целевых индикаторов"', error.toString());
                this.loading = false;
            }
        },

        setTargetIndicator(response) {
            if (response.length > 0 && this.indicatorsArr.length > 0) {
                for (const indicator of this.indicatorsArr) {
                    for (const resp of response) {
                        if (indicator.forecastId === resp.forecastId) {
                            indicator[resp.year] = {
                                nameKk: resp.nameKk,
                                nameRu: resp.nameRu,
                                isEditable: this.uniqueYears.has(resp.year)
                            }
                        }
                    }
                }
            }
        },

        async getTargetIndicatorDataNew() {
            for (const indicator of this.indicatorsArr) {
                const forecastId = indicator.forecastId

                for (const year of this.curYear.periods) {
                    const foundItem = await this.getNote(year, forecastId) ?? {
                        nameKk: '',
                        nameRu: '',
                    }

                    indicator[year] = {
                        nameKk: foundItem.nameKk,
                        nameRu: foundItem.nameRu,
                        isEditable: this.uniqueYears.has(year)
                    }

                }
            }
        },

        async getNote(year, forecastId) {

            try {
                const url =
                    `/api/explanatory-note/indicator?abp=${this.curAbp.abp}&prg=${this.curPrg.prg}&gu=${this.curGu.gu}&budget_type_id=${this.curBudgetType.budget}&cur_year=${this.curYear.year}&year=${year}&region=${this.curRegion.region}&budget_variant_id=${this.curBudgetVariant.variant}&gkkp=${this.curGkkp?.code}&is_gkkp=${this.isGkkp}&forecast_id=${forecastId}`;
                const response = await fetch(url);

                if (response.ok) {
                    const text = await response.text();
                    return text ? JSON.parse(text) : null;

                } else {
                    this.makeToast('Предупреждение', 'Ошибка получения данных');
                    return null;
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
                makeToast(this, 'danger', 'Ошибка загрузки "Целевых индикаторов"', error.toString());
                this.loading = false;
                return null;
            }
        },

        closeDescriptionForm() {
            this.modalVisible = false;
            this.descriptionArea = '';
        },
        editDescription(lang, show, descrItem, goalItem, prgItem, year) {

            if (show) {
                this.modalVisible = show;
                this.modalTitle = descrItem.name_ru;
                this.targetItems.forecastId = prgItem.forecastId;
                this.targetItems.prgId = prgItem.id;
                this.targetItems.goalId = goalItem.id;
                this.targetItems.indicatorId = descrItem.id;
                this.descriptionAreaNew = {
                    nameKk: descrItem[year]?.nameKk ?? "",
                    nameRu: descrItem[year]?.nameRu ?? ""
                }
                this.descYear = year
            }
        },
        addDescriptionForm() {
            const targetIndicator = {
                id: null,
                nameKk: '',
                nameRu: '',
                forecastId: null,
                prgId: null,
                goalId: null,
                indicatorId: null,
                lang: ''
            }

            targetIndicator.nameKk = this.descriptionAreaNew.nameKk;
            targetIndicator.nameRu = this.descriptionAreaNew.nameRu;
            targetIndicator.forecastId = this.targetItems.forecastId;
            targetIndicator.prgId = this.targetItems.prgId;
            targetIndicator.goalId = this.targetItems.goalId;
            targetIndicator.indicatorId = this.targetItems.indicatorId;
            const language = {
                'kk': this.kk,
                'ru': this.ru
            }
            targetIndicator[this.descYear] = {
                nameKk: this.descriptionAreaNew.nameKk,
                nameRu: this.descriptionAreaNew.nameRu,
            }

            setValues(this.indicatorsArr, targetIndicator, language, this.descYear);
            this.closeDescriptionForm();

            function setValues(indicatorsArr, targetIndicator, language, year) {

                const arr = [];
                if (indicatorsArr.length > 0 && targetIndicator) {
                    for (const indicator of indicatorsArr) {
                        if (indicator.id === targetIndicator.indicatorId) {
                            if (targetIndicator.lang === language.kk) {
                                indicator.nameKk = targetIndicator.nameKk;
                            } else if (targetIndicator.lang === language.ru) {
                                indicator.nameRu = targetIndicator.nameRu;
                            }
                            indicator.programId = targetIndicator.prgId;
                            indicator.goalId = targetIndicator.goalId;
                            indicator.indicatorId = targetIndicator.indicatorId;
                            indicator[year] = {
                                nameKk: targetIndicator.nameKk,
                                nameRu: targetIndicator.nameRu,
                                isEditable: true
                            }
                        }
                    }
                }
                return arr;
            }
        },

        collapseHide(collapseAll) {
            const prgList = this.programsArr;
            if (prgList.length > 0) {
                for (let i = 0; i < prgList.length; i++) {
                    this.collapseArr[i] = collapseAll;
                }
            }
        },

        collapseAllFunc() {
            for (let i = 0; i < this.collapseArr.length; i++) {
                this.collapseArr[i] = this.collapseAll;
            }
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrSec.length; i++) {
                this.collapseArrSec[i] = this.collapseAll;
            }
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
        },
        collapseItems(functItemIndex) {  // ---------Раскрытие первого уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndex;
            this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
        },
        collapseItemsSec(functItemIndex) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndex;
            this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
        },

        makeToast(title, toastBody) {
            this.$bvToast.toast(toastBody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение с ошибкой
    }
}
</script>
