<template>
    <div class="inner-container explanatory-note">
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="section-title budget"><span>Бюджетное планирование</span></div>
        <div>
            <b-progress
                variant="success"
                v-show="progress<100"
                height="10px"
                :value="progress"
                striped
                animated
            ></b-progress>
        </div>
        <div class="section-subtitle budget">
            <i class="icon icon-grid"></i>
            <span>Бюджетные заявки<c-budg-form-lst :curFormSelect="'/exp-note'"/></span>
            <div class="actions-tab in-tab" id="bp-info-tab" style="top: 100px">

                <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none"
                            no-caret>
                    <template #button-content id="info-dropdown">
                        <div class="btn-download">
                            <div>
                                <span class="left">
                                    <i class="icon icon-info"></i>
                                </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-text>
                        <div class="info-button">
                            <span style="text-transform: none">Инструкция<i
                                class="icon icon-folder"></i></span>
                        </div>
                        <div class="info-text">
                            <p><span class="red bold" style="text-transform: none">Режим "Пояснительная записка"</span>
                            </p>
                            <p><span style="text-transform: none">Данный раздел предназначен для ввода данных пояснительной записки и
                                представления краткого описания ключевых аспектов деятельности ГУ за определенный период времени.
                                Пояснительная записка включает в себя информацию о достигнутых результатах,
                                текущей ситуации, проблемах и предложениях по их решению.</span></p>
                        </div>
                    </b-dropdown-text>
                </b-dropdown>
                <b-dropdown v-if="moduleTabSwitch" variant="link" right
                            toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                                                    <span class="left">
                                                        <i class="icon icon-download"></i> Скачать
                                                    </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-item id="report-first" @click="reportPrg()">ПЗ по ГУ
                    </b-dropdown-item>
                    <b-dropdown-item id="report-first" @click="reportAbp2()">ПЗ по АБП
                    </b-dropdown-item>
                </b-dropdown>
                <!--                <b-dropdown v-if="moduleTabSwitch" variant="link" right-->
                <!--                            toggle-class="text-decoration-none" no-caret>-->
                <!--                    <template #button-content>-->
                <!--                        <div class="btn-download">-->
                <!--                            <div>-->
                <!--                                                    <span class="left">-->
                <!--                                                        <i class="icon icon-download"></i>-->
                <!--                                                        Скачать-->
                <!--                                                    </span>-->
                <!--                                <i class="icon icon-keyboard"></i>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </template>-->
                <!--                    <b-dropdown-item id="report-first" @click="reportAbp()">ПЗ по АБП-->
                <!--                    </b-dropdown-item>-->
                <!--                </b-dropdown>-->
                <!-- Скачивание отчетов -->
                <!--                <b-dropdown v-if="!moduleTabSwitch && !bpMode" variant="link" right-->
                <!--                            toggle-class="text-decoration-none" no-caret>-->
                <!--                    <template #button-content>-->
                <!--                        <div class="btn-download">-->
                <!--                            <div>-->
                <!--                                    <span class="left">-->
                <!--                                        <i class="icon icon-download"></i> Скачать-->
                <!--                                    </span>-->
                <!--                                <i class="icon icon-keyboard"></i>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </template>-->
                <!--                    <b-dropdown-item id="report-first" @click="secondReport()">ПЗ по Бюджетной-->
                <!--                        программе-->
                <!--                    </b-dropdown-item>-->
                <!--                </b-dropdown>-->
            </div>
        </div>
        <div v-if="moduleTabSwitch" class="filter-area">
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span
                            class="rc"><i class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Выбор организации">
                                    <b-form-radio-group
                                        v-model="selectedOrganization"
                                        :options="organizationOptions"
                                        name="organizationOptions"
                                        @change="onOrganizationChange">
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>

                            <!--------------- Плановый период ------------------->
                            <div class="filter-block">
                                <b-form-group label="Плановый период">
                                    <multiselect
                                        :disabled="false"
                                        v-model="curYear"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать год"
                                        :options="yearPeriodsFilter"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="onYearPeriodsFilterChange"
                                        ref="yearPeriodRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Регион">
                                    <multiselect
                                        v-model="curRegion"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать регион"
                                        :options="regionFilter"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="onRegionFilterChange"
                                        ref="regRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Вид данных------------------->
                            <div class="filter-block">
                                <b-form-group label="Вид данных">
                                    <multiselect
                                        v-model="curBudgetType"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать вид данных"
                                        :options="budgetTypeFilter"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="onBudgetTypeFilterChange"
                                        ref="dataTypeRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Версия бюджета------------------->
                            <div class="filter-block">
                                <b-form-group label="Версия бюджета">
                                    <multiselect
                                        v-model="curBudgetVariant"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать версию бюджета"
                                        :options="budgetVariantFilter"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="onBudgetVariantFilterChange"
                                        ref="versionRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Администратор программ abp------------------->
                            <div class="filter-block">
                                <b-form-group label="Адм. программ">
                                    <multiselect
                                        v-model="curAbp"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать адм. программ"
                                        :options="abpFilter"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="onAbpFilterChange"
                                        ref="abpRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Гос.учреждение------------------->
                            <div class="filter-block">
                                <b-form-group label="Гос. учреждение" :disabled="isGkkp">
                                    <multiselect
                                        v-model="curGu"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать гос. учреждение"
                                        :options="guFilter"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="onGuFilterChange"
                                        ref="guRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------ГККП------------------->
                            <div class="filter-block" v-if="isGkkp">
                                <b-form-group label="ГККП">
                                    <multiselect
                                        v-model="curGkkp"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать ГККП"
                                        :options="gkkpFilter"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="onGkkpFilterChange"
                                        ref="gkkpRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-button variant="success" @click="chgParams"
                                          :disabled="isDisabled">Открыть
                                </b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
            <c-breadcrumbs :curYear="curYear" :curRegion="curRegion"
                           :curBudgetType="curBudgetType" :curBudgetVariant="curBudgetVariant"
                           :curAbp="curAbp" :curGu="curGu" :isGkkp="isGkkp" :curGkkp="curGkkp"
                           @openFilterByRef="openFilterByRef"></c-breadcrumbs>
            <div class="table-container">
                <div class="b-table-sticky-header table-responsive-true">
                    <table class="table b-table table-bordered b-table-no-border-collapse">
                        <thead>
                        <tr class="text-center">
                            <th rowspan="2" class="toggle-show">
                                <button type="button" class="btn btn-secondary"
                                        @click="collapse = !collapse;">
                                    <i class="icon icon-chevron-circle" v-if="collapse"></i>
                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                </button>
                            </th>
                            <th style="width: 0px;">ФУНКЦИОНАЛЬНАЯ ГРУППА</th>
                            <th>АБП</th>
                            <th v-if="!isGkkp">ГУ</th>
                            <th v-if="isGkkp">ГККП</th>
                            <th>ПРОГРАММА</th>
                            <th>НАИМЕНОВАНИЕ</th>
                            <th v-if="!isGkkp">СТАТУС БЗ</th>
                            <th>СТАТУС ПОСЛЕДНЕГО РЕДАКТИРОВАНИЯ ПЗ</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>


                        <template v-for="(funcGr, funcGrIndex) in treeData">
                            <tr :key="`funcGrIndex_${funcGrIndex}`" class="table-info">
                                <td class="toggle-show">
                                    <button type="button" class="btn btn-secondary"
                                            @click="collapseItems(funcGrIndex)">
                                        <i class="icon icon-chevron-circle"
                                           v-if="collapseArr[funcGrIndex]"></i>
                                        <i class="icon icon-chevron-circle icon-rotate-180"
                                           v-else></i>
                                    </button>
                                </td>
                                <td colspan="8">{{ funcGr.name }}</td>
                            </tr>

                            <template v-if="!collapseArr[funcGrIndex]">
                                <template v-for="(abp, abpIndex) in funcGr.children">

                                    <tr :key="`abpIndex_${abpIndex}_${funcGrIndex}`"
                                        class="table-info" style="background-color: #ecf7ff">
                                        <td></td>
                                        <td class="toggle-show">
                                            <button type="button" class="btn btn-secondary"
                                                    @click="collapseItemsSec(abpIndex)">
                                                <i class="icon icon-chevron-circle"
                                                   v-if="collapseArrSec[abpIndex]"></i>
                                                <i class="icon icon-chevron-circle icon-rotate-180"
                                                   v-else></i>
                                            </button>
                                        </td>
                                        <td colspan="7">{{ abp.name }}</td>
                                    </tr>


                                    <template v-if="!collapseArrSec[abpIndex]">
                                        <template
                                            v-for="(guGkkp, guGkkpIndex) in abp.children">
                                            <tr :key="`guGkkpIndex_${guGkkpIndex}_${abpIndex}_${funcGrIndex}`"
                                                style="background-color: rgb(236, 247, 255);">
                                                <td colspan="2"></td>
                                                <td class="toggle-show">
                                                    <button type="button"
                                                            class="btn btn-secondary"
                                                            @click="collapseItemsThird(guGkkpIndex)">
                                                        <i class="icon icon-chevron-circle"
                                                           v-if="collapseArrThird[guGkkpIndex]"></i>
                                                        <i class="icon icon-chevron-circle icon-rotate-180"
                                                           v-else></i>
                                                    </button>
                                                </td>
                                                <td colspan="6">{{ guGkkp.name }}</td>
                                            </tr>


                                            <template v-if="!collapseArrThird[guGkkpIndex]">
                                                <template
                                                    v-for="(item, itemIndex) in guGkkp.children">
                                                    <tr :key="`elementItemIndex_${guGkkpIndex}_${abpIndex}_${funcGrIndex}_${itemIndex}`">
                                                        <td colspan="4"></td>
                                                        <td>{{ item.prg }}</td>
                                                        <td>{{ item.nameRu }}</td>
                                                        <td v-if="!isGkkp">
                                                            {{ item.agrNameRu }}
                                                        </td>
                                                        <td>{{ item.updateDate }}</td>
                                                        <td style="text-align: center">
                                                            <b-dropdown
                                                                id="dropdown-dropleft"
                                                                dropleft class="more">
                                                                <template v-slot:button-content>
                                                                    <i class="icon icon-more"></i>
                                                                </template>
                                                                <template>
                                                                    <template
                                                                        v-if="item.editable===true">
                                                                        <b-dropdown-item
                                                                            @click="editBtn(item, true)">
                                                                            Редактировать
                                                                        </b-dropdown-item>
                                                                        <b-dropdown-item
                                                                            @click="deleteBtn(item)">
                                                                            Удалить
                                                                        </b-dropdown-item>
                                                                    </template>
                                                                    <template v-else>
                                                                        <b-dropdown-item
                                                                            @click="editBtn(item, false)">
                                                                            Просмотр
                                                                        </b-dropdown-item>
                                                                    </template>
                                                                </template>
                                                            </b-dropdown>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>


                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <c-explanatoryNoteTabs :curYear="curYear" :curAbp="curAbp" :curGu="curGu"
                               :curRegion="curRegion" :curBudgetType="curBudgetType"
                               :curBudgetVariant="curBudgetVariant" :openAddTab="openAddTab"
                               :curPrg="curPrg" :editable="editable" :userId="userId"
                               :isGkkp="isGkkp"
                               :curGkkp="curGkkp"
                               @moduleTabSwitches="moduleTabSwitches" v-if="openAddTab"/>
    </div>

</template>
<script>

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Multiselect from 'vue-multiselect';
import CBudgetFormsList from "@/modules/budget-request/components/budget-forms-list";
import CBreadCrumbs from './components/table-dri-breadcrumbs.vue';
import ExplanatoryNoteTabs from './components/ExplanatoryNoteTabs'
import {parseInt} from "lodash";
import VueElementLoading from 'vue-element-loading';
import {makeToast} from "@/modules/budget/bip/bip-types";
import axios from "axios";


export default {
    name: "initialFormOfNote",
    components: {
        'c-budg-form-lst': CBudgetFormsList,
        'c-breadcrumbs': CBreadCrumbs,
        'c-explanatoryNoteTabs': ExplanatoryNoteTabs,
        'multiselect': Multiselect,
        'loading': VueElementLoading
    },
    created() {
        this.$watch('collapseAll', this.collapseAllFunc);
    },

    async mounted() {
        this.userId = this.$store.getters.user_uuid;
        await this.setOrganizationOptions();
        await this.setYearPeriodsFilterAndCurYear();
        await this.setRegions()
        await this.setBudgetTypes()
        await this.setBudgetVariants()
        await this.setAbpList()
        await this.setGuGkkpList()
        this.chgParams();
        this.editAccess = await this.getRole();
    },

    data() {
        return {
            userId: null,
            organizationOptions: [
                {text: 'ГУ', value: 'GU', disabled: false},
                {text: 'ГККП', value: 'GKKP', disabled: false}
            ],
            selectedOrganization: 'GU', // По умолчанию выбран ГУ
            isGkkp: false,

            yearPeriodsFilter: [],
            curYear: {},
            regionFilter: [],
            curRegion: null,
            budgetTypeFilter: [],
            curBudgetType: null,
            budgetVariantFilter: [],
            curBudgetVariant: null,
            abpFilter: [],
            curAbp: null,
            guFilter: [],
            curGu: null,
            gkkpFilter: [],
            curGkkp: null,

            treeData: [],

            isDisabled: true,
            collapse: true,
            collapseArr: [],
            collapseArrSec: [],
            collapseArrThird: [],
            loading: false,
            moduleTabSwitch: true,
            openAddTab: false,
            editable: false,
            curPrg: {},
            editAccess: false,
            programList: [],
            progress: 0
        }
    },

    methods: {

        async getRole() {
            const accessLevel = await this.getUserAccess(this.userId);

            if (accessLevel === 1) {
                return false;
            } else if (accessLevel === 2 || accessLevel === 3) {
                return true;
            }
            return false;
        },

        async getUserAccess(userId) {
            let accessLevel = 1;
            const modules = '004.002.009';
            const url = '/api-py/user-modules/' + userId;
            const response = await fetch(url);
            const items = await response.json();
            const access = getAccess(items, modules);
            accessLevel = access.access_level

            function getAccess(items, modules) {
                const res = {};
                if (items.length > 0) {
                    for (const item of items) {
                        if (item.modules === modules) {
                            return item;
                        }
                    }
                }
                return res;
            }

            return accessLevel;
        },

        async setOrganizationOptions() {
            const userSettings = await this.getOrganizationOptions();

            if (userSettings.onlyGU && userSettings.onlyGKKP) {
                this.organizationOptions = [
                    {text: 'ГУ', value: 'GU', disabled: false},
                    {text: 'ГККП', value: 'GKKP', disabled: false}
                ];
                this.selectedOrganization = 'GU';
            } else if (userSettings.onlyGU) {
                this.organizationOptions = [
                    {text: 'ГУ', value: 'GU', disabled: false},
                    {text: 'ГККП', value: 'GKKP', disabled: true}
                ];
                this.selectedOrganization = 'GU';
            } else if (userSettings.onlyGKKP) {
                this.organizationOptions = [
                    {text: 'ГУ', value: 'GU', disabled: true},
                    {text: 'ГККП', value: 'GKKP', disabled: false}
                ];
                this.selectedOrganization = 'GKKP';
                this.isGkkp = true
            } else {
                this.organizationOptions = [
                    {text: 'ГУ', value: 'GU', disabled: true},
                    {text: 'ГККП', value: 'GKKP', disabled: true}
                ];
                this.selectedOrganization = 'GU';
            }
        },

        async getOrganizationOptions() {
            try {
                // Получаем данные для ГУ
                const urlGu = '/api-py/user-gu/' + this.userId;
                const guArr = await fetch(urlGu).then(response => response.json());

                // Получаем данные для ГККП
                const urlGkkp = '/api-py/user-kgkp/' + this.userId;
                const gkkpArr = await fetch(urlGkkp).then(response => response.json());

                // Проверяем условия
                if (guArr.length > 0 && gkkpArr.length === 0) {
                    // Если только ГУ
                    return {onlyGU: true, onlyGKKP: false};
                } else if (guArr.length === 0 && gkkpArr.length > 0) {
                    // Если только ГККП
                    return {onlyGU: false, onlyGKKP: true};
                } else {
                    // Если доступны и ГУ, и ГККП
                    return {onlyGU: true, onlyGKKP: true};
                }
            } catch (error) {
                this.makeToast('Ошибка получения настроек пользователя:', error);
                return {onlyGU: false, onlyGKKP: false}; // Возвращаем значение по умолчанию в случае ошибки
            }
        },

        async onOrganizationChange(value) {
            this.isGkkp = value === 'GKKP';
            await this.setYearPeriodsFilterAndCurYear();
            await this.setRegions()
            await this.setBudgetTypes()
            await this.setBudgetVariants()
            await this.setAbpList()
            await this.setGuGkkpList()
        },

        setYearPeriodsFilterAndCurYear() {
            const curYear = new Date().getFullYear();
            const periodLst = [];
            const startDate = 2021; //так как разработка е-Акимат началась с 2021 года
            const nextYear = curYear + 1;
            const endDate = nextYear + 2;
            for (let year = endDate; year >= startDate; year--) {
                periodLst.push({
                    name: `${year} - ${year + 2}`,
                    year: year,
                    periods: [year, year + 1, year + 2]
                });
                if (nextYear === year) {
                    this.curYear = {
                        name: `${year} - ${year + 2}`,
                        year: year,
                        periods: [year, year + 1, year + 2]
                    };
                }
            }
            this.yearPeriodsFilter = periodLst;
        },

        async onYearPeriodsFilterChange(value) {
            this.curYear = value;
            await this.setRegions()
            await this.setBudgetTypes()
            await this.setBudgetVariants()
            await this.setAbpList()
            await this.setGuGkkpList()
        },


        async setRegions() {
            const resultArr = await this.getRegions();
            if (resultArr.length === 0) {
                this.regionFilter = []
                this.curRegion = null
                this.budgetTypeFilter = []
                this.curBudgetType = null
                this.budgetVariantFilter = []
                this.curBudgetVariant = null
                this.abpFilter = []
                this.curAbp = null
                this.guFilter = []
                this.curGu = null
                this.gkkpFilter = []
                this.curGkkp = null
                return
            }
            this.curRegion = {
                region: resultArr[0].region.code,
                nameRu: resultArr[0].region.nameRu,
                nameKk: resultArr[0].region.nameKk,
                nameEn: resultArr[0].region.nameEn,
                name: this.setNameByLocale(resultArr[0].region, resultArr[0].region.code)
            };
            this.regionFilter = []
            this.regionFilter = resultArr.map(item => {
                const nameLocale = this.setNameByLocale(item.region, item.region.code)
                return {
                    region: item.region.code,
                    nameRu: item.region.nameRu,
                    nameKk: item.region.nameKk,
                    nameEn: item.region.nameEn,
                    name: nameLocale
                }
            });
        },

        async getRegions() {
            this.loading = true;
            try {
                const url = `/api/explanatory-note/regions?cur_year=${this.curYear.year}&user_id=${this.userId}&is_gkkp=${this.isGkkp}`;
                const result = await fetch(url).then(response => response.json());
                if (result) {
                    this.loading = false;
                    return result;
                }
            } catch (error) {
                this.makeToast('Ошибка загрузки данных', 'По данному запросу ничего не найдено');
                this.loading = false;
                return null;
            }
        },

        async onRegionFilterChange(value) {
            this.curRegion = value;
            await this.setBudgetTypes()
            await this.setBudgetVariants()
            await this.setAbpList()
            await this.setGuGkkpList()
        },

        async setBudgetTypes() {
            const resultArr = await this.getBudgetTypes();
            if (resultArr.length === 0) {
                this.budgetTypeFilter = []
                this.curBudgetType = null
                this.budgetVariantFilter = []
                this.curBudgetVariant = null
                this.abpFilter = []
                this.curAbp = null
                this.guFilter = []
                this.curGu = null
                this.gkkpFilter = []
                this.curGkkp = null
                return
            }
            this.curBudgetType = {
                budget: resultArr[0].budgetType.code,
                nameRu: resultArr[0].budgetType.nameRu,
                nameKk: resultArr[0].budgetType.nameKk,
                nameEn: resultArr[0].budgetType.nameEn,
                name: this.setNameByLocale(resultArr[0].budgetType, resultArr[0].budgetType.code)

            };
            this.budgetTypeFilter = []
            this.budgetTypeFilter = resultArr
                .sort((a, b) => a.budgetType.code.localeCompare(b.budgetType.code)) // Сортировка по code
                .map(item => {
                    const nameLocale = this.setNameByLocale(item.budgetType, item.budgetType.code);
                    return {
                        budget: item.budgetType.code,
                        nameRu: item.budgetType.nameRu,
                        nameKk: item.budgetType.nameKk,
                        nameEn: item.budgetType.nameEn,
                        name: nameLocale,
                    }
                });
        },

        async getBudgetTypes() {
            this.loading = true;
            try {
                const url = `/api/explanatory-note/budget-types?cur_year=${this.curYear.year}&user_id=${this.userId}&region=${this.curRegion.region}&is_gkkp=${this.isGkkp}`;
                const result = await fetch(url).then(response => response.json());
                if (result) {
                    this.loading = false;
                    return result;
                }
            } catch (error) {
                this.makeToast('Ошибка загрузки данных', 'По данному запросу ничего не найдено');
                this.loading = false;
                return null;
            }
        },

        async onBudgetTypeFilterChange(value) {
            this.curBudgetType = value;
            await this.setBudgetVariants()
            await this.setAbpList()
            await this.setGuGkkpList()
        },

        async setBudgetVariants() {
            const resultArr = await this.getBudgetVariants();
            if (resultArr.length === 0) {
                this.budgetVariantFilter = []
                this.curBudgetVariant = null
                this.abpFilter = []
                this.curAbp = null
                this.guFilter = []
                this.curGu = null
                this.gkkpFilter = []
                this.curGkkp = null
                return
            }

            this.curBudgetVariant = {
                variant: resultArr[0].budgetVariant.code,
                nameRu: resultArr[0].budgetVariant.nameRu,
                nameKk: resultArr[0].budgetVariant.nameKk,
                nameEn: resultArr[0].budgetVariant.nameEn,
                attribute: resultArr[0].budgetVariant.attribute,
                status: resultArr[0].budgetVariant.status,
                name: this.variantNameLangSupport(resultArr[0].budgetVariant)

            };

            this.budgetVariantFilter = [];
            this.budgetVariantFilter = resultArr.map(item => {
                return {
                    variant: item.budgetVariant.code,
                    nameRu: item.budgetVariant.nameRu,
                    nameKk: item.budgetVariant.nameKk,
                    nameEn: item.budgetVariant.nameEn,
                    attribute: item.budgetVariant.attribute,
                    status: item.budgetVariant.status,
                    name: this.variantNameLangSupport(item.budgetVariant),

                };
            });
        },

        async getBudgetVariants() {
            this.loading = true;
            try {

                const url = `/api/explanatory-note/budget-variants?cur_year=${this.curYear.year}&user_id=${this.userId}&region=${this.curRegion.region}&budget_type=${this.curBudgetType.budget}&is_gkkp=${this.isGkkp}`;

                const result = await fetch(url).then(response => response.json());
                if (result) {
                    this.loading = false;
                    return result;
                }
            } catch (error) {
                this.makeToast('Ошибка загрузки данных', 'По данному запросу ничего не найдено');
                this.loading = false;
                return null;
            }
        },

        async onBudgetVariantFilterChange(value) {
            this.curBudgetVariant = value;
            await this.setAbpList()
            await this.setGuGkkpList()
        },

        async setAbpList() {
            const resultArr = await this.getAbpList();
            if (resultArr.length === 0) {
                this.abpFilter = []
                this.curAbp = null
                this.guFilter = []
                this.curGu = null
                this.gkkpFilter = []
                this.curGkkp = null
                return
            }

            this.curAbp = {
                abp: resultArr[0].abp.code,
                nameRu: resultArr[0].abp.nameRu,
                nameKk: resultArr[0].abp.nameKk,
                nameEn: resultArr[0].abp.nameEn,
                name: this.setNameByLocale(resultArr[0].abp, resultArr[0].abp.code),
            };

            this.abpFilter = []
            this.abpFilter = resultArr.map(item => {
                const nameLocale = this.setNameByLocale(item.abp, item.abp.code);
                return {
                    abp: item.abp.code,
                    nameRu: item.abp.nameRu,
                    nameKk: item.abp.nameKk,
                    nameEn: item.abp.nameEn,
                    name: nameLocale,
                }

            });
        },


        async getAbpList() {
            this.loading = true;
            try {
                const url = `/api/explanatory-note/abp?cur_year=${this.curYear.year}&user_id=${this.userId}&region=${this.curRegion.region}&budget_type=${this.curBudgetType.budget}&budget_variant=${this.curBudgetVariant.variant}&is_gkkp=${this.isGkkp}`;
                const result = await fetch(url).then(response => response.json());
                if (result) {
                    this.loading = false;
                    return result;
                }
            } catch (error) {
                this.makeToast('Ошибка загрузки данных', 'По данному запросу ничего не найдено');
                this.loading = false;
                return null;
            }
        },

        async onAbpFilterChange(value) {
            this.curAbp = value;
            await this.setGuGkkpList()
        },

        async setGuGkkpList() {
            const resultArr = await this.getGuGkkpList();

            if (resultArr.length === 0) {
                this.guFilter = []
                this.curGu = null
                this.gkkpFilter = []
                this.curGkkp = null
                return
            }

            this.curGu = {
                gu: resultArr[0].gu.code,
                nameRu: resultArr[0].gu.nameRu,
                nameKk: resultArr[0].gu.nameKk,
                nameEn: resultArr[0].gu.nameEn,
                name: this.setNameByLocale(resultArr[0].gu, resultArr[0].gu.code),
            }

            this.guFilter = []
            this.guFilter = resultArr.map(item => {
                const nameLocale = this.setNameByLocale(item.gu, item.gu.code);
                return {
                    gu: item.gu.code,
                    nameRu: item.gu.nameRu,
                    nameKk: item.gu.nameKk,
                    nameEn: item.gu.nameEn,
                    name: nameLocale,
                }
            });


            if (this.isGkkp) {
                this.curGkkp = {
                    gkkp: resultArr[0].gkkp.code,
                    nameRu: resultArr[0].gkkp.nameRu,
                    nameKk: resultArr[0].gkkp.nameKk,
                    nameEn: resultArr[0].gkkp.nameEn,
                    name: this.setNameByLocale(resultArr[0].gkkp, resultArr[0].gkkp.code),
                    gu: this.curGu
                }
                this.gkkpFilter = []
                this.gkkpFilter = resultArr.map(item => {
                    return {
                        gkkp: item.gkkp.code,
                        nameRu: item.gkkp.nameRu,
                        nameKk: item.gkkp.nameKk,
                        nameEn: item.gkkp.nameEn,
                        name: this.setNameByLocale(item.gkkp, item.gkkp.code),
                        gu: {
                            gu: item.gu.code,
                            nameRu: item.gu.nameRu,
                            nameKk: item.gu.nameKk,
                            nameEn: item.gu.nameEn,
                            name: this.setNameByLocale(item.gu, item.gu.code),
                        }
                    }
                });
            }
        },

        async getGuGkkpList() {
            this.loading = true;
            try {
                const url = `/api/explanatory-note/gu-gkkp?cur_year=${this.curYear.year}&user_id=${this.userId}&region=${this.curRegion.region}&budget_type=${this.curBudgetType.budget}&budget_variant=${this.curBudgetVariant.variant}&abp=${this.curAbp.abp}&is_gkkp=${this.isGkkp}`;
                const result = await fetch(url).then(response => response.json());
                if (result) {
                    this.loading = false;
                    this.isDisabled = false;
                    return result;
                }
            } catch (error) {
                this.makeToast('Ошибка загрузки данных', 'По данному запросу ничего не найдено');
                this.loading = false;
                return null;
            }
        },


        onGuFilterChange(value) {
            this.curGu = value;
        },
        onGkkpFilterChange(value) {
            this.curGu = value.gu;
            this.curGkkp = value;
        },

        setNameByLocale(obj, codeName) {
            if (obj === null) {
                return null;
            }
            const code = codeName ? `${codeName} - ` : "";

            const currentLocale = this.$i18n.locale; // Получаем текущий язык
            if (currentLocale === "ru") {
                return code + obj.nameRu;
            } else if (currentLocale === "kk") {
                return code + obj.nameKk;
            } else if (currentLocale === "en") {
                return code + obj.nameEn;
            } else {
                return `${code}${obj.nameRu || ""}`;
            }

        },

        variantNameLangSupport(obj) {
            const langCode = this.$i18n.locale;
            let res = this.setNameByLocale(obj, null);
            if (obj.status === true) {
                switch (langCode) {
                    case 'kk':
                        res = res + ' (бекiт)';
                        break;
                    case 'en':
                        res = res + ' (appr)';
                        break;
                    default:
                        res = res + ' (утв)';
                        break;
                }
            }
            if (obj.attribute === true) {
                switch (langCode) {
                    case 'kk':
                        res = res + ' (өзек)';
                        break;
                    case 'en':
                        res = res + ' (act)';
                        break;
                    default:
                        res = res + ' (акт)';
                        break;
                }
            }
            return res;
        },

        chgParams() {
            this.loadBudgetRequestFormTotal();
            this.collapse = true;
            if (this.collapseArr.length > 0) {
                for (let i = 0; i < this.collapseArr; i++) {
                    this.collapseArr[i] = this.collapse;
                }
            }
        },

        async loadBudgetRequestFormTotal() {
            this.loading = true;
            this.treeData = [];
            this.programList = [];

            try {
                if (this.curYear.year === '' &&
                    this.curRegion.region === '' &&
                    this.curAbp.abp === '' &&
                    this.curGu.gu === '' &&
                    this.curBudgetVariant === null) {
                    this.loading = false;
                    this.makeToast('Статус поиска', 'По данному запросу ничего не найдено');
                    return
                }

                const url = `/api/explanatory-note/total-new?cur_year=${this.curYear.year}&user_id=${this.userId}&region=${this.curRegion.region}&budget_type=${this.curBudgetType.budget}&budget_variant=${this.curBudgetVariant.variant}&abp=${this.curAbp.abp}&gu=${this.curGu.gu}&is_gkkp=${this.isGkkp}&gkkp=${this.curGkkp?.gkkp}`;

                const result = await fetch(url).then(response => response.json());
                const localTree = [];
                for (const funGr of result) {
                    const funGrNode = {
                        gr: funGr.gr,
                        nameRu: funGr.nameRu,
                        nameKk: funGr.nameKk,
                        nameEn: "",
                        name: this.setNameByLocale(funGr, funGr.gr),
                        children: [] // Узлы `abp`
                    };

                    for (const abp of funGr.child) {
                        const abpNode = {
                            abp: abp.abp,
                            nameRu: abp.nameRu,
                            nameKk: abp.nameKk,
                            nameEn: "",
                            name: this.setNameByLocale(abp, abp.abp),
                            children: [] // Узлы `gu`
                        };

                        for (const gu of abp.child) {
                            const guNode = {
                                code: gu.guOrGkkp,
                                nameRu: gu.nameRu,
                                nameKk: gu.nameKk,
                                nameEn: "",
                                name: this.setNameByLocale(gu, gu.guOrGkkp),
                                children: [] // Узлы `prg`
                            };

                            for (const prg of gu.child) {
                                const prgNode = {
                                    gr: funGr.gr,
                                    abp: abp.abp,
                                    prg: prg.prg,
                                    name: this.setNameByLocale(prg, prg.prg),
                                    nameRu: prg.nameRu,
                                    nameKk: prg.nameKk,
                                    agrNameEn: prg.formTotalAgreement.nameEn,
                                    agrNameKk: prg.formTotalAgreement.nameKk,
                                    agrNameRu: prg.formTotalAgreement.nameRu,
                                    editable: prg.editStatus,
                                    pprItems: prg.pprItems,
                                    pprSet: prg.pprSet,
                                    updateDate: prg.dateOfRequestNoteUpdate
                                        ? new Date(prg.dateOfRequestNoteUpdate).toLocaleString().split(",")[0]
                                        : ""
                                };

                                guNode.children.push(prgNode); // Добавляем узел `prg` к `gu`
                                this.programList.push({
                                    prg: prg.prg,
                                    nameRu: prg.nameRu,
                                    nameKk: prg.nameKk,
                                })
                            }

                            abpNode.children.push(guNode); // Добавляем узел `gu` к `abp`
                        }

                        funGrNode.children.push(abpNode); // Добавляем узел `abp` к `funGr`
                    }

                    localTree.push(funGrNode); // Добавляем корневой узел `funGr` в дерево
                }
                this.treeData = localTree
                this.loading = false;
                console.log("TREE DATA-------------------------------------")
                console.log(localTree)
            } catch (error) {
                this.loading = false;
                this.makeToast('Статус поиска', 'По данному запросу ничего не найдено');
            }
        },


        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        collapseAllFunc() {
            for (let i = 0; i < this.collapseArr.length; i++) {
                this.collapseArr[i] = this.collapse;
            }
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrSec.length; i++) {
                this.collapseArrSec[i] = this.collapse;
            }
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrThird.length; i++) {
                this.collapseArrThird[i] = this.collapse;
            }
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
        },
        collapseItems(functItemIndex) {  // ---------Раскрытие первого уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndex;
            this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
        },
        collapseItemsSec(functItemIndex) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndex;
            this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
        },
        collapseItemsThird(guItemIndex) {  // ---------Раскрытие четвертого уровня при нажатии на стрелку вниз
            const rowIndex = guItemIndex;
            this.collapseArrThird[rowIndex] = !this.collapseArrThird[rowIndex];
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
        },

        editBtn(item, editStatus) {
            if (item) {
                this.openAddTab = true;
                this.moduleTabSwitch = false;
                this.curPrg = item;
                this.editable = editStatus;
            }
        },
        moduleTabSwitches(item) {
            if (item) {
                this.openAddTab = !item;
                this.moduleTabSwitch = item;
            }
        },

        async deleteBtn(item) {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите удалить данные?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        const curYear = this.curYear.year;
                        const curRegion = this.curRegion.region;
                        const gu = this.curGu.gu;
                        const gkkp = this.curGkkp?.gkkp;
                        const budgetTypeId = this.curBudgetType.budget;
                        const budgetVariantId = this.curBudgetVariant.variant;
                        const params = {
                            'id': parseInt(item.id),
                            'gr': parseInt(item.gr),
                            'abp': parseInt(item.abp),
                            'prg': parseInt(item.prg),
                            'curYear': parseInt(curYear),
                            'curRegion': curRegion,
                            'gu': gu,
                            'gkkp': gkkp,
                            'budgetTypeId': budgetTypeId,
                            'budgetVariantId': budgetVariantId,
                            'userId': this.userId
                        };
                        try {
                            const url = '/api/explanatory-note/remove';
                            const response = await fetch(url, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(params) // массив будет направлен на kotlin
                            });
                            const result = await response.json();
                            if ((response.status === 200) && (result === true)) {
                                this.makeToast('Очистка заявки', 'Данные успешно удалены');
                                await this.loadBudgetRequestFormTotal();
                            }
                        } catch {
                            this.makeToast('Очистка заявки', 'Ошибка удаления заявки');
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                    this.loading = false;
                });
        },

        makeToast(title, toastBody) {
            this.$bvToast.toast(toastBody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение с ошибкой
        async getNote(note) {
            try {
                const url = '/api/explanatory-note/get-notes';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(note) // массив будет направлен на kotlin
                });
                if (response.ok) {
                    const text = await response.text();
                    return text ? JSON.parse(text) : null;
                } else {
                    console.error('Ошибка получения данных:', response.id);
                    this.makeToast('Предупреждение', 'Ошибка получения данных');
                }
            } catch (error) {
                console.error('Ошибка получения записей:', error);
            }
            return null
        },
        async getNote2(year, forecastId, prg) {

            try {
                const url =
                    `/api/explanatory-note/indicator?abp=${this.curAbp.abp}&prg=${prg}&gu=${this.curGu.gu}&budget_type_id=${this.curBudgetType.budget}&cur_year=${this.curYear.year}&year=${year}&region=${this.curRegion.region}&budget_variant_id=${this.curBudgetVariant.variant}&gkkp=${this.curGkkp?.code}&is_gkkp=${this.isGkkp}&forecast_id=${forecastId}`;
                const response = await fetch(url);

                if (response.ok) {
                    const text = await response.text();
                    return text ? JSON.parse(text) : null;

                } else {
                    this.makeToast('Предупреждение', 'Ошибка получения данных');
                    return null;
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
                makeToast(this, 'danger', 'Ошибка загрузки "Целевых индикаторов"', error.toString());
                this.loading = false;
                return null;
            }
        },
        async reportPrg() {
            this.progress = 0;
            // Пример: увеличиваем прогресс в процессе загрузки
            const interval = setInterval(() => {
                if (this.progress < 90) {
                    this.progress += 10;
                } else {
                    clearInterval(interval);
                }
            }, 500);

            const list1 = [];
            const list2 = [];
            const list3 = [];

            for (const prg of this.programList) {

                const note = {
                    curYear: this.curYear.year,
                    region: this.curRegion.region,
                    budgetTypeId: this.curBudgetType.budget,
                    budgetVariantId: this.curBudgetVariant.variant,
                    abp: this.curAbp.abp,
                    gu: this.curGu.gu,
                    gkkp: this.isGkkp ? this.curGkkp.gkkp : null,
                    prg: prg.prg,

                    id: null,
                    ppr: null,
                    year: this.curYear.year,
                    userId: this.userId,
                    nameKk: "",
                    nameRu: "",
                    nameEn: "",
                    titleIndicator: null,
                    titleSorting: null,
                    updateDate: null,
                }
                const response = await this.getNote(note)
                response.forEach((d) => {
                    list1.push({
                        id: d.id,
                        abp: d.abp,
                        prg: d.prg,
                        ppr: d.ppr,
                        gu: d.gu,
                        dataTypeId: d.budgetTypeId,
                        year: d.year,
                        curYear: d.curYear,
                        region: d.region,
                        variant: d.budgetVariantId,
                        nameKk: d.nameKk,
                        nameRu: d.nameRu,
                        titleIndicator: d.titleIndicator,
                        titleSorting: d.titleSorting,
                        userId: d.userId
                    });
                });
                const url = '/api/explanatory-note/budget-request-note/forecast/' +
                    this.curAbp.abp + '/' +
                    prg.prg + '/' +
                    this.curYear.year;
                const responseGoal = await fetch(url).then(response => response.json());


                for (const indicator of responseGoal.indicatorList) {
                    const forecastId = indicator.forecastId
                    for (const year of this.curYear.periods) {
                        const foundItem = await this.getNote2(year, forecastId, prg.prg)
                        if (foundItem) {

                            const goalItem = responseGoal.goalList.find(it =>
                                it.programId === foundItem.programId);
                            const indicatorItem = responseGoal.indicatorList.find(it =>
                                it.goalId === goalItem.id &&
                                it.programId === foundItem.programId);
                            const programItem = responseGoal.programList.find(it =>
                                it.forecastId === foundItem.forecastId)
                            list2.push({
                                programId: programItem.id,
                                programCode: programItem.code,
                                programEn: programItem.name_en,
                                programKk: programItem.name_kz,
                                programRu: programItem.name_ru,
                                goalId: goalItem.id,
                                goalCode: goalItem.code,
                                goalEn: goalItem.name_en,
                                goalKk: goalItem.name_kz,
                                goalRu: goalItem.name_ru,
                                indicatorId: indicatorItem.id,
                                indicatorCode: indicatorItem.code,
                                indicatorEn: indicatorItem.name_en,
                                indicatorKk: indicatorItem.name_kz,
                                indicatorRu: indicatorItem.name_ru,
                                noteKk: foundItem ? foundItem.nameKk : "",
                                noteRu: foundItem ? foundItem.nameRu : "",
                                forecastId: foundItem.forecastId,
                                prg: prg.prg,
                                year: year
                            });
                        }

                    }
                }

                for (const year of this.curYear.periods) {

                    const url2 =
                        `/api/explanatory-note/get_ppr?year=${year}&abp=${this.curAbp.abp}&prg=${prg.prg}&gu=${this.curGu.gu}&variant=${this.curBudgetVariant.variant}&bin=${this.curGkkp?.code}&is_gkkp=${this.isGkkp}`;

                    let responseExpenses = await fetch(url2);
                    responseExpenses = await responseExpenses.json();

                    responseExpenses.forEach((re) => {
                        const foundItem = response.find(it =>
                            it.abp === re.abp &&
                            it.ppr === re.ppr &&
                            it.prg === re.prg &&
                            it.year === year &&
                            it.titleIndicator.trim() === "ppr"
                        );
                        list3.push({
                            year: year,
                            abp: re.abp,
                            gu: re.gu,
                            nameKk: re.name_kk,
                            nameRu: re.name_ru,
                            ppr: re.ppr,
                            prg: re.prg,
                            value: re.value,
                            noteRu: foundItem ? foundItem.nameRu : "",
                            noteKk: foundItem ? foundItem.nameKk : ""
                        });
                    });
                }
            }

            try {

                const langCode = this.$i18n.locale;
                const title = {
                    variant: this.curBudgetVariant.variant,
                    programList: this.programList,
                    guNameKk: this.curGu.nameKk,
                    guNameRu: this.curGu.nameRu,
                    programKk: this.curBudgetType.nameKk,
                    programRu: this.curBudgetType.nameRu,
                    programNameKk: this.curBudgetVariant.nameKk,
                    programNameRu: this.curBudgetVariant.nameRu,
                    yearPeriod: this.curYear.periods,
                    year: this.curYear.year,
                    abp: this.curAbp.abp,
                    region: this.curRegion.region,
                    langCode: langCode
                }

                const params = {
                    data1: list1,
                    data2: list2,
                    data3: list3,
                    title: title
                };

                if (params) {
                    const axiosResponse = await axios.post('/api/word/write-gu', params, {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const url = window.URL.createObjectURL(new Blob([axiosResponse.data]));
                    const link = document.createElement('a');


                    link.href = url;
                    link.setAttribute('download',
                        `ПЗ_${this.curGu.gu}.docx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.progress = 100;

                }
            } catch (error) {
                this.progress = 0;
                makeToast(this, 'danger', 'Ошибка загрузки Описаний на казахском и русском языках', error.toString());
            }

        },

        async reportAbp() {
            this.progress = 0;
            // Пример: увеличиваем прогресс в процессе загрузки
            const interval = setInterval(() => {
                if (this.progress < 90) {
                    this.progress += 10;
                } else {
                    clearInterval(interval);
                }
            }, 500);

            const total = [];

            const list1 = [];
            const list2 = [];
            const list3 = [];


            for (const gu of this.guFilter) {
                const url = `/api/explanatory-note/total-new?cur_year=${this.curYear.year}&user_id=${this.userId}&region=${this.curRegion.region}&budget_type=${this.curBudgetType.budget}&budget_variant=${this.curBudgetVariant.variant}&abp=${this.curAbp.abp}&gu=${gu.gu}&is_gkkp=${this.isGkkp}&gkkp=${this.curGkkp?.gkkp}`;
                const result = await fetch(url).then(response => response.json());
                for (const funGr of result) {
                    for (const abp of funGr.child) {
                        for (const gu of abp.child) {
                            const guNode = {
                                code: gu.guOrGkkp,
                                nameRu: gu.nameRu,
                                nameKk: gu.nameKk,
                                prgList: [] // Узлы `prg`
                            };
                            for (const prg of gu.child) {
                                const prgNode = {
                                    prg: prg.prg,
                                    nameRu: prg.nameRu,
                                    nameKk: prg.nameKk,
                                };
                                guNode.prgList.push(prgNode);
                            }
                            total.push(guNode)
                        }
                    }
                }
            }


            for (const gu of total) {

                for (const prg of gu.prgList) {
                    const note = {
                        curYear: this.curYear.year,
                        region: this.curRegion.region,
                        budgetTypeId: this.curBudgetType.budget,
                        budgetVariantId: this.curBudgetVariant.variant,
                        abp: this.curAbp.abp,
                        gu: gu.code,
                        gkkp: this.isGkkp ? this.curGkkp.gkkp : null,
                        prg: prg.prg,
                        id: null,
                        ppr: null,
                        year: this.curYear.year,
                        userId: this.userId,
                        nameKk: "",
                        nameRu: "",
                        nameEn: "",
                        titleIndicator: null,
                        titleSorting: null,
                        updateDate: null,
                    }
                    const response = await this.getNote(note)
                    response.forEach((d) => {
                        list1.push({
                            id: d.id,
                            abp: d.abp,
                            prg: d.prg,
                            ppr: d.ppr,
                            gu: d.gu,
                            dataTypeId: d.budgetTypeId,
                            year: d.year,
                            curYear: d.curYear,
                            region: d.region,
                            variant: d.budgetVariantId,
                            nameKk: d.nameKk,
                            nameRu: d.nameRu,
                            titleIndicator: d.titleIndicator,
                            titleSorting: d.titleSorting,
                            userId: d.userId
                        });
                    });
                    const url = '/api/explanatory-note/budget-request-note/forecast/' +
                        this.curAbp.abp + '/' +
                        prg.prg + '/' +
                        this.curYear.year;
                    const responseGoal = await fetch(url).then(response => response.json());


                    for (const indicator of responseGoal.indicatorList) {
                        const forecastId = indicator.forecastId
                        for (const year of this.curYear.periods) {
                            const foundItem = await this.getNote2(year, forecastId, prg.prg)
                            if (foundItem) {

                                const goalItem = responseGoal.goalList.find(it =>
                                    it.programId === foundItem.programId);
                                const indicatorItem = responseGoal.indicatorList.find(it =>
                                    it.goalId === goalItem.id &&
                                    it.programId === foundItem.programId);
                                const programItem = responseGoal.programList.find(it =>
                                    it.forecastId === foundItem.forecastId)
                                list2.push({
                                    programId: programItem.id,
                                    programCode: programItem.code,
                                    programEn: programItem.name_en,
                                    programKk: programItem.name_kz,
                                    programRu: programItem.name_ru,
                                    goalId: goalItem.id,
                                    goalCode: goalItem.code,
                                    goalEn: goalItem.name_en,
                                    goalKk: goalItem.name_kz,
                                    goalRu: goalItem.name_ru,
                                    indicatorId: indicatorItem.id,
                                    indicatorCode: indicatorItem.code,
                                    indicatorEn: indicatorItem.name_en,
                                    indicatorKk: indicatorItem.name_kz,
                                    indicatorRu: indicatorItem.name_ru,
                                    noteKk: foundItem ? foundItem.nameKk : "",
                                    noteRu: foundItem ? foundItem.nameRu : "",
                                    forecastId: foundItem.forecastId,
                                    prg: prg.prg,
                                    year: year
                                });
                            }

                        }
                    }

                    for (const year of this.curYear.periods) {

                        const url2 =
                            `/api/explanatory-note/get_ppr?year=${year}&abp=${this.curAbp.abp}&prg=${prg.prg}&gu=${gu.code}&variant=${this.curBudgetVariant.variant}&bin=${this.curGkkp?.code}&is_gkkp=${this.isGkkp}`;

                        let responseExpenses = await fetch(url2);
                        responseExpenses = await responseExpenses.json();

                        responseExpenses.forEach((re) => {
                            const foundItem = response.find(it =>
                                it.abp === re.abp &&
                                it.ppr === re.ppr &&
                                it.prg === re.prg &&
                                it.year === year &&
                                it.titleIndicator.trim() === "ppr"
                            );
                            list3.push({
                                year: year,
                                abp: re.abp,
                                gu: re.gu,
                                nameKk: re.name_kk,
                                nameRu: re.name_ru,
                                ppr: re.ppr,
                                prg: re.prg,
                                value: re.value,
                                noteRu: foundItem ? foundItem.nameRu : "",
                                noteKk: foundItem ? foundItem.nameKk : ""
                            });
                        });
                    }

                }
                try {

                    const langCode = this.$i18n.locale;
                    const title = {
                        variant: this.curBudgetVariant.variant,
                        programList: this.programList,
                        guNameKk: gu.nameKk,
                        guNameRu: gu.nameRu,
                        programKk: this.curBudgetType.nameKk,
                        programRu: this.curBudgetType.nameRu,
                        programNameKk: this.curBudgetVariant.nameKk,
                        programNameRu: this.curBudgetVariant.nameRu,
                        yearPeriod: this.curYear.periods,
                        year: this.curYear.year,
                        abp: this.curAbp.abp,
                        region: this.curRegion.region,
                        langCode: langCode
                    }

                    const params = {
                        data1: list1,
                        data2: list2,
                        data3: list3,
                        title: title
                    };

                    if (params) {
                        const axiosResponse = await axios.post('/api/word/write-gu', params, {
                            responseType: 'blob',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        const url = window.URL.createObjectURL(new Blob([axiosResponse.data]));
                        const link = document.createElement('a');


                        link.href = url;
                        link.setAttribute('download',
                            `ПЗ_${gu.code}.docx`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.progress = 100;

                    }
                } catch (error) {
                    this.progress = 0;
                    makeToast(this, 'danger', 'Ошибка загрузки Описаний на казахском и русском языках', error.toString());
                }

            }

        },

        async reportAbp2() {
            this.progress = 0;
            const interval = setInterval(() => {
                if (this.progress < 90) {
                    this.progress += 10;
                } else {
                    clearInterval(interval);
                }
            }, 500);

            try {
                // Собираем данные
                const total = [];
                const list1 = [];
                const list2 = [];
                const list3 = [];

                // Ваш существующий код сбора данных
                for (const gu of this.guFilter) {
                    const url = `/api/explanatory-note/total-new?cur_year=${this.curYear.year}&user_id=${this.userId}&region=${this.curRegion.region}&budget_type=${this.curBudgetType.budget}&budget_variant=${this.curBudgetVariant.variant}&abp=${this.curAbp.abp}&gu=${gu.gu}&is_gkkp=${this.isGkkp}&gkkp=${this.curGkkp?.gkkp}`;
                    const result = await fetch(url).then(response => response.json());

                    // Обработка результата
                    for (const funGr of result) {
                        for (const abp of funGr.child) {
                            for (const gu of abp.child) {
                                total.push({
                                    code: gu.guOrGkkp,
                                    nameRu: gu.nameRu,
                                    nameKk: gu.nameKk,
                                    prgList: gu.child?.map(prg => ({  // Добавляем prgList
                                        prg: prg.prg,
                                        nameRu: prg.nameRu,
                                        nameKk: prg.nameKk
                                    })) || []  // Запасной вариант на случай отсутствия child
                                });
                            }
                        }
                    }
                }

                // Собираем дополнительные данные (ваш существующий код)
                // ... list1, list2, list3 заполняются как раньше ...
                for (const gu of total) {
                    if (!gu.prgList) continue;
                    for (const prg of gu.prgList) {
                        const note = {
                            curYear: this.curYear.year,
                            region: this.curRegion.region,
                            budgetTypeId: this.curBudgetType.budget,
                            budgetVariantId: this.curBudgetVariant.variant,
                            abp: this.curAbp.abp,
                            gu: gu.code,
                            gkkp: this.isGkkp ? this.curGkkp.gkkp : null,
                            prg: prg.prg,
                            id: null,
                            ppr: null,
                            year: this.curYear.year,
                            userId: this.userId,
                            nameKk: "",
                            nameRu: "",
                            nameEn: "",
                            titleIndicator: null,
                            titleSorting: null,
                            updateDate: null,
                        }
                        const response = await this.getNote(note)
                        response.forEach((d) => {
                            list1.push({
                                id: d.id,
                                abp: d.abp,
                                prg: d.prg,
                                ppr: d.ppr,
                                gu: d.gu,
                                dataTypeId: d.budgetTypeId,
                                year: d.year,
                                curYear: d.curYear,
                                region: d.region,
                                variant: d.budgetVariantId,
                                nameKk: d.nameKk,
                                nameRu: d.nameRu,
                                titleIndicator: d.titleIndicator,
                                titleSorting: d.titleSorting,
                                userId: d.userId
                            });
                        });
                        const url = '/api/explanatory-note/budget-request-note/forecast/' +
                            this.curAbp.abp + '/' +
                            prg.prg + '/' +
                            this.curYear.year;
                        const responseGoal = await fetch(url).then(response => response.json());


                        for (const indicator of responseGoal.indicatorList) {
                            const forecastId = indicator.forecastId
                            for (const year of this.curYear.periods) {
                                const foundItem = await this.getNote2(year, forecastId, prg.prg)
                                if (foundItem) {

                                    const goalItem = responseGoal.goalList.find(it =>
                                        it.programId === foundItem.programId);
                                    const indicatorItem = responseGoal.indicatorList.find(it =>
                                        it.goalId === goalItem.id &&
                                        it.programId === foundItem.programId);
                                    const programItem = responseGoal.programList.find(it =>
                                        it.forecastId === foundItem.forecastId)
                                    list2.push({
                                        programId: programItem.id,
                                        programCode: programItem.code,
                                        programEn: programItem.name_en,
                                        programKk: programItem.name_kz,
                                        programRu: programItem.name_ru,
                                        goalId: goalItem.id,
                                        goalCode: goalItem.code,
                                        goalEn: goalItem.name_en,
                                        goalKk: goalItem.name_kz,
                                        goalRu: goalItem.name_ru,
                                        indicatorId: indicatorItem.id,
                                        indicatorCode: indicatorItem.code,
                                        indicatorEn: indicatorItem.name_en,
                                        indicatorKk: indicatorItem.name_kz,
                                        indicatorRu: indicatorItem.name_ru,
                                        noteKk: foundItem ? foundItem.nameKk : "",
                                        noteRu: foundItem ? foundItem.nameRu : "",
                                        forecastId: foundItem.forecastId,
                                        prg: prg.prg,
                                        year: year
                                    });
                                }

                            }
                        }

                        for (const year of this.curYear.periods) {

                            const url2 =
                                `/api/explanatory-note/get_ppr?year=${year}&abp=${this.curAbp.abp}&prg=${prg.prg}&gu=${gu.code}&variant=${this.curBudgetVariant.variant}&bin=${this.curGkkp?.code}&is_gkkp=${this.isGkkp}`;

                            let responseExpenses = await fetch(url2);
                            responseExpenses = await responseExpenses.json();

                            responseExpenses.forEach((re) => {
                                const foundItem = response.find(it =>
                                    it.abp === re.abp &&
                                    it.ppr === re.ppr &&
                                    it.prg === re.prg &&
                                    it.year === year &&
                                    it.titleIndicator.trim() === "ppr"
                                );
                                list3.push({
                                    year: year,
                                    abp: re.abp,
                                    gu: re.gu,
                                    nameKk: re.name_kk,
                                    nameRu: re.name_ru,
                                    ppr: re.ppr,
                                    prg: re.prg,
                                    value: re.value,
                                    noteRu: foundItem ? foundItem.nameRu : "",
                                    noteKk: foundItem ? foundItem.nameKk : ""
                                });
                            });
                        }

                    }
                }
                // Подготавливаем данные для отправки на сервер
                const requestData = {
                    gus: total,
                    langCode: this.$i18n.locale,
                    commonData: {
                        data1: list1,
                        data2: list2,
                        data3: list3,
                        programList: this.programList,
                        variant: this.curBudgetVariant.variant,
                        programKk: this.curBudgetType.nameKk,
                        programRu: this.curBudgetType.nameRu,
                        programNameKk: this.curBudgetVariant.nameKk,
                        programNameRu: this.curBudgetVariant.nameRu,
                        yearPeriod: this.curYear.periods,
                        year: this.curYear.year,
                        abp: this.curAbp.abp,
                        region: this.curRegion.region
                    }
                };

                // Отправляем запрос на создание ZIP-архива
                const response = await axios.post('/api/word/write-zip', requestData, {
                    responseType: 'blob'
                });

                // Скачиваем архив
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.curAbp.abp}_${this.curYear.year}.zip`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.progress = 100;

            } catch (error) {
                this.progress = 0;
                makeToast(this, 'danger', 'Ошибка создания архива', error.toString());
            }
        },


        // async secondReport() {
        //     const url =
        //         `${this.curAbp.abp}/${this.prgItems.prg}/${this.curGu.code}/${this.curBudgetType.code}/${this.curYear.year}/${this.curYear.year}/${this.curRegion.region}/${this.curBudgetVariant.variant}`;
        //     try {
        //         // list1 - START
        //         const list1 = []
        //         let responseInfo = await fetch(`/api/explanatory-note/budget-request-note/get/${url}`);
        //         responseInfo = await responseInfo.json();
        //         responseInfo.forEach((d) => {
        //             list1.push({
        //                 id: d.id,
        //                 abp: d.abp,
        //                 prg: d.prg,
        //                 ppr: d.ppr,
        //                 gu: d.gu,
        //                 dataTypeId: d.dataTypeId,
        //                 year: d.year,
        //                 curYear: d.curYear,
        //                 region: d.region,
        //                 variant: d.variant,
        //                 nameKk: d.nameKk,
        //                 nameRu: d.nameRu,
        //                 titleIndicator: d.titleIndicator,
        //                 titleSorting: d.titleSorting,
        //                 userId: d.userId
        //             });
        //         });
        //         // list1 - END
        //
        //         // list2 - START
        //         const list2 = [];
        //         let responseIndicator = await fetch(`/api/explanatory-note/note-target-indicator/get/${url}`);
        //         responseIndicator = await responseIndicator.json();
        //
        //         let responseGoal = await fetch(`/api/explanatory-note/budget-request-note/forecast/${url}`);
        //         responseGoal = await responseGoal.json();
        //
        //         const filteredList = this.getOnlyObjects(responseGoal.programList)
        //
        //         filteredList.forEach((d) => {
        //             const goalItem = responseGoal.goalList.find(it =>
        //                 it.programId === d.id && it.forecastId === d.forecastId);
        //             const indicatorItem = responseGoal.indicatorList.find(it =>
        //                 it.goalId === goalItem.id &&
        //                 it.programId === d.id);
        //             const note = responseIndicator.find(it =>
        //                 it.programId === d.id &&
        //                 it.goalId === goalItem.id &&
        //                 it.indicatorId === indicatorItem.id &&
        //                 it.forecastId === d.forecastId)
        //             list2.push({
        //                 programId: d.id,
        //                 programCode: d.code,
        //                 programEn: d.name_en,
        //                 programKk: d.name_kz,
        //                 programRu: d.name_ru,
        //                 goalId: goalItem.id,
        //                 goalCode: goalItem.code,
        //                 goalEn: goalItem.name_en,
        //                 goalKk: goalItem.name_kz,
        //                 goalRu: goalItem.name_ru,
        //                 indicatorId: indicatorItem.id,
        //                 indicatorCode: indicatorItem.code,
        //                 indicatorEn: indicatorItem.name_en,
        //                 indicatorKk: indicatorItem.name_kz,
        //                 indicatorRu: indicatorItem.name_ru,
        //                 noteKk: note ? note.nameKk : "-",
        //                 noteRu: note ? note.nameRu : "-",
        //                 forecastId: d.forecastId,
        //             });
        //         })
        //
        //
        //         // list2 - END
        //
        //         // list3 - START
        //         const list3 = [];
        //         let responseExpenses = await fetch(`/api/explanatory-note/budget-request-form-total/get_ppr/${this.curAbp.abp}/${this.prgItems.prg}/${this.curGu.code}/${this.curYear.year}/${this.curBudgetVariant.variant}`);
        //         responseExpenses = await responseExpenses.json();
        //
        //         responseExpenses.forEach((re) => {
        //             const foundItem = responseInfo.find(it =>
        //                 it.abp === re.abp &&
        //                 it.ppr === re.ppr &&
        //                 it.prg === re.prg &&
        //                 it.titleIndicator.trim() === "ppr"
        //             );
        //             list3.push({
        //                 abp: re.abp,
        //                 gu: re.gu,
        //                 nameKk: re.name_kk,
        //                 nameRu: re.name_ru,
        //                 ppr: re.ppr,
        //                 prg: re.prg,
        //                 value: re.value,
        //                 noteRu: foundItem ? foundItem.nameRu : "-",
        //                 noteKk: foundItem ? foundItem.nameKk : "-"
        //             });
        //         });
        //         // list3 - END
        //
        //         const title = {
        //             prgNameRu: this.prgItems.name_ru,
        //             prgNameKk: this.prgItems.name_kk,
        //             guNameKk: this.curGu.nameKk,
        //             guNameRu: this.curGu.nameRu,
        //             programKk: this.curBudgetType.nameKk,
        //             programRu: this.curBudgetType.nameRu,
        //             programNameKk: this.curBudgetVariant.nameKk,
        //             programNameRu: this.curBudgetVariant.nameRu,
        //             yearPeriod: this.curYear.name
        //         }
        //
        //         const params = {
        //             data1: list1,
        //             data2: list2,
        //             data3: list3,
        //             title: title
        //         };
        //
        //
        //         if (params) {
        //             const axiosResponse = await axios.post('/api/word/write-prg', params, {
        //                 responseType: 'blob',
        //                 headers: {
        //                     'Content-Type': 'application/json'
        //                 }
        //             });
        //
        //             const url = window.URL.createObjectURL(new Blob([axiosResponse.data]));
        //             const link = document.createElement('a');
        //
        //
        //             function formatNumber(num) {
        //                 const s = num.toString();
        //                 let zero = '';
        //                 for (let i = 1; i <= (3 - s.length); i++) {
        //                     zero += '0';
        //                 }
        //                 return zero + s;
        //             }
        //
        //             const num = formatNumber(this.prgItems.prg)
        //             link.href = url;
        //             link.setAttribute('download',
        //                 `ПЗ_${this.curGu.code}_${num}.docx`);
        //             document.body.appendChild(link);
        //             link.click();
        //             document.body.removeChild(link);
        //
        //         }
        //     } catch (error) {
        //         console.error(error);
        //         makeToast(this, 'danger', 'Ошибка загрузки Описаний на казахском и русском языках', error.toString());
        //     }
        //
        //
        // },

    }
}
</script>

<style lang="scss">

#report-first {
    text-transform: none;
}

.exp-textarea-style {
    position: relative;
    border: 1px solid #BCD2E0;
    border-radius: 5px;
    background-color: #EEF1F8;
    padding: 5px 10px;
    height: 90px;
    font-size: 14px;
    font-weight: 500;

    .tas-action {
        position: absolute;
        color: #2196F3;
        top: 5px;
        right: 10px;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            color: #1b7cc9;
        }
    }

}

.text-area-block-empty {
    border: 1px solid #EE0505;
    border-radius: 5px;
}

.text-area-block {
    border: 1px solid #BCD2E0;
    border-radius: 5px;
}

.exp-note-table {
    tbody {
        td {
            padding: 10px !important;
        }
    }
}

.enf-modal {
    .modal-title {
        font-size: 14px;
        font-weight: 700;
        color: #1C2A3E;
        border-bottom: none;
    }


    legend {
        margin-bottom: 10px;
    }

    .form-control {
        min-height: 65px;
        border: 1px solid #BCD2E0;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
}

.explanatory-note {
    .enf-form-group {
        position: relative;

        .icon-pencil-edit,
        .icon-eye-open {
            position: absolute;
            right: 15px;
            color: #2196F3;
            cursor: pointer;
        }

        .icon-eye-open {
            top: 9px;
        }

        .icon-pencil-edit {
            top: 12px;
        }
    }

    .enf-top-info {
        position: absolute;
        top: 40px;
        right: 10px;
        z-index: 1005;

        .duplicate-radio {
            margin-top: 6px;
            display: flex;
            align-content: center;

            .custom-radio {
                text-transform: uppercase;
            }

            .enf-label {
                color: #1C2A3E;
                font-size: 14px;
                margin-top: 6px;
                font-weight: 700;
                margin-right: 20px;
            }
        }
    }

    .add-program {
        padding: 15px 20px;
        display: inline-flex;
        font-size: 14px;
        align-content: center;
        color: #2196F3 !important;
        cursor: pointer;

        i {
            margin-right: 15px;
            font-size: 20px;
        }

        b {
            text-decoration: underline;
        }
    }

    .td-link:hover {
        color: #2196F3 !important;
        cursor: pointer;
    }

    .more {
        .text-danger {
            button {
                color: #dc3545 !important;
            }
        }
    }

    .explanatory-note-form {
        .enf-title {
            padding: 10px 20px;
            background-color: #fff;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-content: center;
            justify-content: flex-start;

            i {
                color: #2196F3;
                font-size: 17px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .enf-row {
            margin: 0;
            padding: 10px;
            border-top: 1px solid #D3DBE3;
            border-bottom: 1px solid #D3DBE3;
        }

        .form-group {
            margin: 0;
        }

        .form-row {
            margin: 0;
            align-items: flex-start;
        }
    }

    .table-container {
        .add-indicator {
            .icon-plus-circle {
                font-size: 20px;
                color: #2196F3;
            }

            span {
                color: #2196F3;
                display: inline-flex;
                align-content: center;
                cursor: pointer;

                b {
                    text-decoration: underline;
                }

                i {
                    margin-right: 10px;
                }
            }
        }

        .enf-row {
            margin: 0;
            padding: 0;
            border-top: none;
            border-bottom: none;
        }

    }


}
</style>