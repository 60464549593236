<template>
    <div class="filter-breadcrumb">
        <span class="item-breadcrumb" @click="openFilterByRef('yearPeriodRef')">
            {{ curYear.name }}
        </span>
        <template v-if="curRegion">
            <span class="item-breadcrumb" @click="openFilterByRef('regRef')">
                {{ curRegion.name }}
            </span>
        </template>
        <template v-if="curBudgetType">
            <span class="item-breadcrumb" @click="openFilterByRef('dataTypeRef')">
                {{ curBudgetType.nameRu }}
            </span>
        </template>
        <template v-if="curBudgetVariant">
            <span class="item-breadcrumb" @click="openFilterByRef('versionRef')">
                {{ curBudgetVariant.nameRu }}
            </span>
        </template>
        <template v-if="curAbp">
            <span class="item-breadcrumb" @click="openFilterByRef('abpRef')">
                {{ curAbp.abp }}-АБП
            </span>
        </template>
        <template v-if="curGu">
            <span class="item-breadcrumb" @click="openFilterByRef('guRef')">
                {{ curGu.gu }}-ГУ
            </span>
        </template>
        <template v-if="isGkkp">
            <span class="item-breadcrumb" @click="openFilterByRef('gkkpRef')">
                {{ curGkkp.gkkp }}-ГККП
            </span>
        </template>
        <template v-if="curPrg">
            <span class="item-breadcrumb">
                {{ curPrg.name }}
            </span>
        </template>
    </div>
</template>
<script>
export default {
    name: 'CBreadCrumbs',
    props: {
        curYear: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curRegion: {
            type: Object,
            required: false
        },
        curAbp: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curGu: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        isGkkp: {
          type: Boolean,
            required: false,
        },
        curGkkp: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curBudgetType: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curBudgetVariant: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curPrg: {
            type: Object,
            required: false,
            default: () => {
            }
        }
    },
    mounted() {
    },
    data() {
        return {}
    },
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        }
    }
};

</script>
<style scoped>

</style>
