<template>
    <div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="filter-container">
            <div class="left-content"></div>
            <div class="right-content">
                <b-button v-if="openAddTab" variant="light" @click="goBack">
                    <i class="icon icon-keyboard icon-rotate-180"></i>
                    Назад к списку
                </b-button>
                <template v-if="editAccess">
                    <b-button v-if="openAddTab" variant="success" @click="saveData">
                        Сохранить
                    </b-button>
                </template>
            </div>
        </div>

        <c-breadcrumbs :curYear="curYear" :curRegion="curRegion" :curAbp="curAbp" :curGu="curGu"
                       :isGkkp="isGkkp" :curGkkp="curGkkp" :curBudgetType="curBudgetType"
                       :curBudgetVariant="curBudgetVariant" :curPrg="curPrg"></c-breadcrumbs>

        <div class="inner-container">
            <b-tabs class="tabs-in-tabs">

                <b-tab title="основные сведения">
                    <div class="table-container exp-note-table">
                        <b-table-simple class="table-striped">
                            <b-thead>
                                <tr>
                                    <b-th rowspan="2">Наименование</b-th>
                                    <b-th colspan="3" class="text-center">Плановый период</b-th>
                                </tr>
                                <tr>
                                    <template v-for="(year, index) in curYear.periods">
                                        <b-th class="text-center" width="26%" :key="index">{{
                                                year
                                            }} {{ "ГОД" }}
                                        </b-th>
                                    </template>
                                </tr>
                            </b-thead>
                            <b-tbody>
                                <tr v-for="(descItem, descItemIndex) of tabDataOne"
                                    :key="descItemIndex">
                                    <td class="bold">{{ descItem.nameRu }}</td>
                                    <template v-for="(year, index) in curYear.periods">
                                        <td :key="index">
                                            <div class="exp-textarea-style"
                                                 v-bind:class="[allowable(descItem.years[index]) ? descriptionNotEmpty : descriptionEmpty]"
                                                 style="text-align: justify">

                                                    <span class="enf-placeholder"
                                                          v-if="!descItem.years[index].noteRu">
                                                        {{
                                                            descItem.years[index].isEditable ?
                                                                "Введите описание" : ""
                                                        }}
                                                    </span>
                                                <template
                                                    v-if="descItem.years[index].noteRu.length<150">
                                                    {{ descItem.years[index].noteRu }}
                                                </template>
                                                <template v-else>{{
                                                        descItem.years[index].noteRu.slice(0,
                                                            150)
                                                    }}...
                                                </template>

                                                <template
                                                    v-if="editAccess && descItem.years[index].isEditable">
                                                    <i class="tas-action icon icon-pencil-edit"
                                                       @click="editDescription(true,
                                                       descItem.years[index])"></i>
                                                </template>
                                                <template v-else>
                                                    <i class="tas-action icon icon-eye-open"
                                                       @click="editDescription(descItem.years[index].isEditable,
                                                                       descItem.years[index])"></i>
                                                </template>
                                            </div>
                                        </td>
                                    </template>
                                </tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-tab>

                <b-tab title="цели бп и кр">
                    <ExplanatoryNoteAddTable :curYear="curYear" :curAbp="curAbp" :curGu="curGu"
                                             :curGkkp="curGkkp" :isGkkp="isGkkp"
                                             :curRegion="curRegion" :curBudgetType="curBudgetType"
                                             :curBudgetVariant="curBudgetVariant"
                                             :curPrg="curPrg" :saveBtnPressed="saveBtnPressed"
                                             :editAccess="editAccess" :uniqueYears="uniqueYears"
                                             @needSaved="needSaved" :needSave="needSave"
                                             @indicatorsArr="indicatorsArr"/>
                </b-tab>

                <b-tab title="расходы">
                    <div class="table-container exp-note-table">
                        <b-table-simple class="table-striped">
                            <b-thead>
                                <tr>
                                    <b-th rowspan="2">Наименование</b-th>
                                    <b-th colspan="3" class="text-center">Плановый период</b-th>
                                </tr>
                                <tr>
                                    <template v-for="(year, index) in curYear.periods">
                                        <b-th class="text-center" width="26%" :key="index">{{
                                                year
                                            }} {{ "ГОД" }}
                                        </b-th>
                                    </template>
                                </tr>
                            </b-thead>
                            <b-tbody>
                                <template v-if="tabDataThree && tabDataThree.pprList">
                                    <template
                                        v-for="(pprItem, pprItemIndex) of tabDataThree.pprList">
                                        <tr class="enf-row" :key="'pprItemIndex'+pprItemIndex">
                                            <td class="bold">{{ setNulls(pprItem.ppr) }} -
                                                {{ pprItem.nameRu }}:
                                            </td>
                                            <td v-for="(year, index) in curYear.periods"
                                                :key="index">
                                                <div class="bold text-center mb-3">
                                                    {{
                                                        pprItem.years[index].value.toLocaleString()
                                                    }}
                                                    <template v-if="pprItem.years[index].value">тыс.
                                                        тенге
                                                    </template>
                                                </div>
                                                <div class="exp-textarea-style"
                                                     v-bind:class="[allowable(pprItem.years[index]) ? descriptionNotEmpty : descriptionEmpty]">
                                                    <div class="box">

                                                        <span
                                                            class="enf-placeholder"
                                                            v-if="!pprItem.years[index].noteRu">
                                                             {{
                                                                pprItem.years[index].isEditable ? "Введите описание" : ""
                                                            }}
                                                        </span>
                                                        <template
                                                            v-if="pprItem.years[index].noteRu.length<150">
                                                            {{ pprItem.years[index].noteRu }}
                                                        </template>
                                                        <template v-else>{{
                                                                pprItem.years[index].noteRu.slice(0, 150)
                                                            }}...
                                                        </template>

                                                        <template v-if="editAccess &&
                                                    pprItem.years[index].isEditable &&
                                                    pprItem.years[index].value > 0">
                                                            <i class="tas-action icon icon-pencil-edit"
                                                               @click="editDescription( true,
                                                           pprItem.years[index])"></i>
                                                        </template>
                                                        <template v-else>
                                                            <i class="tas-action icon icon-eye-open"
                                                               @click="editDescription(
                                                               pprItem.years[index].isEditable &&
                                                    pprItem.years[index].value > 0,
                                                                pprItem.years[index])"></i>
                                                        </template>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </template>

                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-tab>
                <!--                <div class="enf-top-info" v-if="editAccess && isFirstYear">-->
                <!--                    <div class="duplicate-radio">-->
                <!--                        <span class="enf-label">Дублировать на следующие года:</span>-->
                <!--                        <b-form-radio-group id="radio-slots" v-model="isDuplicate">-->
                <!--                            <template v-if="duplicatePermission">-->
                <!--                                <b-form-radio value="true">Да</b-form-radio>-->
                <!--                            </template>-->
                <!--                            <template v-else>-->
                <!--                                <b-form-radio disabled value="true">Да</b-form-radio>-->
                <!--                            </template>-->
                <!--                            <b-form-radio value="false">Нет</b-form-radio>-->
                <!--                        </b-form-radio-group>-->
                <!--                    </div>-->
                <!--                </div>-->
            </b-tabs>
        </div>
        <b-modal
            v-model="modalVisible"
            modal-class="enf-modal"
            size="lg"
            centered
            :title="modalTitle"
            hide-footer
        >
            <b-form-group>
                <template #label>{{ 'На русском:' }}</template>
                <template v-if="editAccess">
                    <b-form-textarea placeholder="введите описание"
                                     v-model="modalArea.nameRu"
                                     style="text-align: justify"></b-form-textarea>
                </template>
                <template v-else>
                    <b-form-textarea readonly v-model="modalArea.nameRu"
                                     style="text-align: justify"></b-form-textarea>
                </template>

            </b-form-group>
            <b-form-group>
                <template #label>{{ 'Қазақ тілінде:' }}</template>
                <template v-if="editAccess">
                    <b-form-textarea placeholder="сипаттаманы енгізіңіз"
                                     v-model="modalArea.nameKk"
                                     style="text-align: justify"></b-form-textarea>
                </template>
                <template v-else>
                    <b-form-textarea readonly v-model="modalArea.nameKk"
                                     style="text-align: justify"></b-form-textarea>
                </template>
            </b-form-group>
            <template v-if="editAccess">
                <b-button variant="primary" @click="addDescriptionForm">Сохранить</b-button>
            </template>
            <b-button variant="light" @click="closeDescriptionForm">Отменить</b-button>
        </b-modal>
    </div>
</template>

<script>
import ExplanatoryNoteAddTable from './ExplanatoryNoteAddTable.vue';
import CBreadCrumbs from './table-dri-breadcrumbs.vue';
import VueElementLoading from "vue-element-loading";
import {makeToast} from "@/modules/budget/bip/bip-types";
import {parseInt} from "lodash";

export default {
    name: "ExplanatoryNoteTabs",
    components: {
        'c-breadcrumbs': CBreadCrumbs,
        'loading': VueElementLoading,
        ExplanatoryNoteAddTable
    },
    props: {
        openAddTab: {
            type: Boolean,
            required: true
        },
        curPrg: {
            type: Object,
            required: true
        },
        curYear: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curRegion: {
            type: Object,
            required: true
        },
        curAbp: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curGu: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curBudgetType: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        curBudgetVariant: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        isGkkp: {
            type: Boolean,
            required: false
        },
        curGkkp: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        editable: {
            type: Boolean,
            required: true
        },
        userId: {
            type: String,
            required: false,
            default: () => {
            }
        },
    },

    async created() {
        this.uniqueYears = this.getUniqueYears()
        await this.getTabOne();
        await this.getTabThree();
    },
    data() {
        return {
            uniqueYears: [],
            loading: false,
            editAccess: false,
            descriptionEmpty: 'text-area-block',
            descriptionNotEmpty: 'text-area-block-empty',

            modalVisible: false,
            modalTitle: '',
            modalArea: {
                nameRu: '',
                nameKk: '',
                nameEn: ''
            },
            modalItem: null,
            dataToSend: [],
            dataToSend2: [],
            needSave: false,
            saveBtnPressed: false,
            tabDataOne: null,
            tabDataThree: null,
            isFull: true,
        }
    },

    async mounted() {
        this.editAccess = await this.getRole();
    },

    methods: {

        async getRole() {
            const accessLevel = await this.getUserAccess(this.userId);

            if (accessLevel === 1) {
                return false;
            } else if (accessLevel === 2 || accessLevel === 3) {
                if (this.editable === true) {
                    return true;
                }
            }
            return false;
        },

        async getUserAccess(userId) {
            let accessLevel = 1;
            const modules = '004.002.009';
            const url = '/api-py/user-modules/' + userId;
            const response = await fetch(url);
            const items = await response.json();
            const access = getAccess(items, modules);
            accessLevel = access.access_level

            function getAccess(items, modules) {
                const res = {};
                if (items.length > 0) {
                    for (const item of items) {
                        if (item.modules === modules) {
                            return item;
                        }
                    }
                }
                return res;
            }

            return accessLevel;
        },

        getUniqueYears() {
            const uniqueYears = new Set();
            Object.values(this.curPrg.pprItems).forEach(items => {
                items.forEach(item => {
                    if (item.year) {
                        uniqueYears.add(item.year);
                    }
                });
            });
            return uniqueYears
        },

        //ОСНОВНЫЕ СВЕДЕНИЯ
        async getTabOne() {
            const indicatorArr = [
                {
                    index: 0,
                    nameRu: 'Описание достигнутых показателей / результатов за отчетный финансовый год',
                    nameKk: '',
                    nameEn: '',
                    titleIndicator: 'finance_result',
                    years: []
                },
                {
                    index: 1,
                    nameRu: 'Описание текущей ситуации и имеющихся проблем',
                    nameKk: '',
                    nameEn: '',
                    titleIndicator: 'current_situation',
                    years: []
                },
                {
                    index: 2,
                    nameRu: 'Описание путей улучшения ситуации и решения проблем',
                    nameKk: '',
                    nameEn: '',
                    titleIndicator: 'solve_decision',
                    years: []
                },
                {
                    index: 3,
                    nameRu: 'Описание путей достижения планируемых целевых индикаторов',
                    nameKk: '',
                    nameEn: '',
                    titleIndicator: 'target_indicator_achievement',
                    years: []
                }
            ]


            try {
                for (const year of this.curYear.periods) {

                    for (const indicator of indicatorArr) {
                        const note = {
                            id: null,
                            abp: this.curAbp.abp,
                            prg: this.curPrg.prg,
                            ppr: null,
                            gu: this.curGu.gu,
                            gkkp: this.isGkkp ? this.curGkkp.gkkp : null,
                            budgetTypeId: this.curBudgetType.budget,
                            year: year,
                            curYear: this.curYear.year,
                            region: this.curRegion.region,
                            budgetVariantId: this.curBudgetVariant.variant,
                            userId: this.userId,
                            nameKk: "",
                            nameRu: "",
                            nameEn: "",
                            titleIndicator: indicator.titleIndicator,
                            titleSorting: null,
                            updateDate: null,
                        }

                        const response = await this.getNote(note) ?? note

                        indicator.years.push({
                            index: indicator.index,
                            id: response.id ?? null,
                            nameRu: indicator.nameRu,
                            nameKk: indicator.nameKk,
                            nameEn: indicator.nameEn,
                            titleIndicator: indicator.titleIndicator,
                            noteKk: response.nameKk ?? "",
                            noteRu: response.nameRu ?? "",
                            noteEn: response.nameEn ?? "",
                            year: year,
                            isEditable: this.uniqueYears.has(year)
                        })
                    }
                }
                this.tabDataOne = indicatorArr; // Сохраняем объект
                console.log("TAB-ONE-------------------------------------")
                console.log(this.tabDataOne)

            } catch
                (error) {
                makeToast(this, 'danger', 'Ошибка загрузки Описаний на казахском и русском языках', error.toString());
            }
        },

        //РАСХОДЫ
        async getTabThree() {
            const processedData = {
                prg: this.curPrg.prg,
                nameRu: this.curPrg.nameRu,
                nameKk: this.curPrg.nameKk,
                pprList: []
            };

            try {
                for (const key in this.curPrg.pprItems) {
                    const pprData = {
                        ppr: parseInt(key),
                        nameRu: this.curPrg.pprItems[key][0].ppr.nameRu,
                        nameKk: this.curPrg.pprItems[key][0].ppr.nameKk,
                        years: []
                    };

                    for (const [index, year] of this.curYear.periods.entries()) {
                        const note = {
                            id: null,
                            abp: this.curAbp.abp,
                            prg: this.curPrg.prg,
                            ppr: key,
                            gu: this.curGu.gu,
                            gkkp: this.isGkkp ? this.curGkkp.gkkp : null,
                            budgetTypeId: this.curBudgetType.budget,
                            year: year,
                            curYear: this.curYear.year,
                            region: this.curRegion.region,
                            budgetVariantId: this.curBudgetVariant.variant,
                            userId: this.userId,
                            nameKk: "",
                            nameRu: "",
                            nameEn: "",
                            titleIndicator: "ppr",
                            titleSorting: null,
                            updateDate: null,
                        };

                        const response = await this.getNote(note) ?? note;
                        let value = 0;
                        if (this.curPrg.pprItems[key]) {
                            const foundItem = this.curPrg.pprItems[key].find(item => item.year === year);
                            if (foundItem) {
                                value = foundItem.ppr.value || 0;
                            }
                        }

                        pprData.years.push({
                            index: parseInt(key),
                            ppr: parseInt(key),
                            nameRu: this.curPrg.pprItems[key][0].ppr.nameRu,
                            nameKk: this.curPrg.pprItems[key][0].ppr.nameKk,
                            id: response.id ?? null,
                            noteKk: response.nameKk ?? "",
                            noteRu: response.nameRu ?? "",
                            titleIndicator: "ppr",
                            year: year,
                            value: value,
                            isEditable: this.uniqueYears.has(year)
                        });
                    }

                    processedData.pprList.push(pprData);
                }

                console.log("TAB-THREE-------------------------------------");
                console.log(processedData);

                this.tabDataThree = processedData; // Сохраняем объект

            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки Описаний на казахском и русском языках', error.toString());
            }
        },

        async getNote(note) {
            try {
                const url = '/api/explanatory-note/get-note';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(note) // массив будет направлен на kotlin
                });
                if (response.ok) {
                    const text = await response.text();
                    return text ? JSON.parse(text) : null;
                } else {
                    console.error('Ошибка получения данных:', response.id);
                    this.makeToast('Предупреждение', 'Ошибка получения данных');
                }
            } catch (error) {
                console.error('Ошибка получения записей:', error);
            }
            return null
        },

        needSaved() {
            this.needSave = false;
        },

        allowable(descItem) {
            if (this.saveBtnPressed === true) {
                this.descriptionEmpty = 'text-area-block-empty';
                this.descriptionNotEmpty = 'text-area-block';
                if ((descItem.noteRu.length > 0 || descItem.noteKk.length > 0)
                    && this.editAccess && descItem.isEditable) {
                    return true;
                } else if (!descItem.isEditable) {
                    return true;
                }
                this.isFull = false;
            }
            return false;
        },

        indicatorsArr(indicatorsArr) {

            this.dataToSend2 = []
            for (const indicator of indicatorsArr) {
                for (const [index, year] of this.curYear.periods.entries()) {
                    if (indicator && indicator.hasOwnProperty(year)) {
                        if (indicator[year].nameKk === "" && indicator[year].nameRu === "") {
                            continue
                        }
                        this.dataToSend2.push({
                            abp: this.curAbp.abp,
                            prg: this.curPrg.prg,
                            gu: this.curGu.gu,
                            gkkp: this.isGkkp ? this.curGkkp.gkkp : null,
                            budgetTypeId: this.curBudgetType.budget,
                            year: year,
                            curYear: this.curYear.year,
                            region: this.curRegion.region,
                            budgetVariantId: this.curBudgetVariant.variant,
                            forecastId: indicator.forecastId,
                            programId: indicator.programId,
                            goalId: indicator.goalId,
                            indicatorId: indicator.indicatorId,
                            nameKk: indicator[year].nameKk,
                            nameRu: indicator[year].nameRu,
                            userId: this.userId
                        })

                    } else {
                        console.log(`No indicators found for year: ${year}`);
                    }
                }
            }
            this.saveIndicator2();
        },

        async saveIndicator2() {
            // const send = []
            console.log("send data")
            console.log(this.dataToSend)
            console.log("GOING TO SAVE--------->")
            console.log(this.dataToSend2)
            // for (const desc of this.dataToSend) {
            //     send.push(desc)
            // }

            if (this.isFull === false) {
                this.makeToast('Предупреждение', 'Необходимо заполнить все обязательные поля');
            }

            if (this.dataToSend.length === 0 && this.dataToSend2.length === 0) {
                this.makeToast('Предупреждение', 'Необходимо заполнить все обязательные поля');
                return
            }
            this.loading = true;
            try {
                const url = '/api/explanatory-note/add-note';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.dataToSend)
                });
                const result = await response.json();

                const url2 = '/api/explanatory-note/indicator';
                const response2 = await fetch(url2, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.dataToSend2)
                });
                const result2 = await response2.json();

                if ((response.status === 200) && (response2.status === 200)) {
                    this.loading = false;
                    this.makeToast('Сообщение', 'Данные сохранены', '');
                } else {
                    this.loading = false;
                    this.makeToast('Предупреждение', 'Ошибка сохранения данных');
                }
                this.dataToSend = [];
                this.dataToSend2 = [];
            } catch {
                this.loading = false;
                this.makeToast('Предупреждение', 'Ошибка сохранения данных');
            }
        },

        async saveIndicator(mainObject) {
            if (mainObject.length === 0) {
                this.makeToast('Предупреждение', 'Необходимо заполнить все обязательные поля');
                return
            }
            this.loading = true;
            try {
                const url = '/api/explanatory-note/indicator';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(mainObject)
                });
                const result = await response.json();
                if ((response.status === 200) && (result === true)) {
                    this.loading = false;
                    this.makeToast('Сообщение', 'Данные сохранены', '');
                } else {
                    this.loading = false;
                    this.makeToast('Предупреждение', 'Ошибка сохранения данных');
                }
            } catch {
                this.loading = false;
                this.makeToast('Предупреждение', 'Ошибка сохранения данных');
            }
        },

        goBack() {
            this.$emit('moduleTabSwitches', true);
        },  //---При нажатии на кнопку "НАЗАД К СПИСКУ"

        async saveData() {
            this.needSave = true;
            this.saveBtnPressed = true;
        },


        // async getPprList() {
        //     //странные вещи тут !!
        //     this.pprListArr = []
        //     try {
        //         for (const [index, year] of this.curYear.periods.entries()) {
        //
        //             const url =
        //                 `/api/explanatory-note/get_ppr?year=${year}&abp=${this.curAbp.abp}&prg=${this.curPrg.prg}&gu=${this.curGu.gu}&variant=${this.curBudgetVariant.variant}&bin=${this.curGkkp?.gkkp}&isGkkp=${this.isGkkp}`;
        //             const result = await fetch(url).then(response => response.json());
        //             if (result) {
        //                 setAdditionalValuesToArr(this.pprListArr, result, index, year)
        //             }
        //
        //         }
        //     } catch (error) {
        //         makeToast(this, 'danger', 'Ошибка загрузки "Подпрограмм"', error.toString());
        //     }
        //
        //     function setAdditionalValuesToArr(pprListArr, response, index, year) {
        //         const defaultValues = [
        //             {year, id: '', nameKk: '', nameRu: '', value: 0},
        //             {year, id: '', nameKk: '', nameRu: '', value: 0},
        //             {year, id: '', nameKk: '', nameRu: '', value: 0},
        //         ];
        //
        //         if (response.length > 0) {
        //             let indx = 0;
        //
        //             for (const resp of response) {
        //                 const i = ++indx;
        //
        //                 // Проверяем, существует ли элемент в pprListArr с совпадающими gu, abp, prg, и ppr
        //                 const existingItem = pprListArr.find(item =>
        //                     item.gu === resp.gu &&
        //                     item.abp === resp.abp &&
        //                     item.prg === resp.prg &&
        //                     item.ppr === resp.ppr
        //                 );
        //
        //                 if (existingItem) {
        //                     existingItem.years[index] = {
        //                         year: year,
        //                         id: i,
        //                         nameKk: '',
        //                         nameRu: '',
        //                         value: resp.value
        //                     };
        //                 } else {
        //                     // Если совпадений нет, добавляем новый объект
        //                     resp.indx = i;
        //                     resp.nameKk = '';
        //                     resp.nameRu = '';
        //                     resp.years = [...defaultValues];
        //                     resp.years[index] = {
        //                         year: year,
        //                         id: i,
        //                         nameKk: '',
        //                         nameRu: '',
        //                         value: resp.value ?? 0,
        //                     };
        //
        //                     pprListArr.push(resp);
        //                 }
        //             }
        //         }
        //     }
        // },

        setNulls(number) {
            const strNum = number.toString();
            return strNum.padStart(3, '0'); // Дополняем нулями до 3 символов
        },


        editDescription(show, item) {
            console.log("ITEM EDIT--------------------------")
            console.log(item)

            if (show) {
                this.modalVisible = show;
                this.modalItem = item
                this.modalTitle = item.nameRu;
                this.modalArea = {
                    nameKk: item.noteKk,
                    nameRu: item.noteRu
                }
            }

        },

        addDescriptionForm() {
            const index = this.modalItem.index;
            this.modalItem.noteRu = this.modalArea.nameRu;
            this.modalItem.noteKk = this.modalArea.nameKk;

            this.dataToSend.push({
                abp: this.curAbp.abp,
                budgetTypeId: this.curBudgetType.budget,
                budgetVariantId: this.curBudgetVariant.variant,
                curYear: this.curYear.year,
                gkkp: this.isGkkp ? this.curGkkp.gkkp : null,
                gu: this.curGu.gu,
                id: this.modalItem.id,
                nameEn: "",
                nameKk: this.modalArea.nameKk,
                nameRu: this.modalArea.nameRu,
                ppr: this.modalItem.ppr,
                prg: this.curPrg.prg,
                region: this.curRegion.region,
                titleIndicator: this.modalItem.titleIndicator,
                titleSorting: index,
                updateDate: null,
                userId: this.userId,
                year: this.modalItem.year,
                pprSet: this.curPrg.pprSet
            })

            this.closeDescriptionForm();

        },

        closeDescriptionForm() {
            this.modalVisible = false;
        },


        makeToast(title, toastBody) {
            this.$bvToast.toast(toastBody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    }
}
</script>
